/* You can add global styles to this file, and also import other style files */
@import 'assets/css/font-awesome.css';
@import 'assets/css/bootstrap.css';
@import 'assets/css/owl.theme.default.min.css';
@import 'assets/css/owl.carousel.min.css';
@import 'assets/css/animations.css';
@import 'assets/css/animate.css';
@import 'assets/css/cookie-law-info-public.css';
@import 'assets/css/cookie-law-info-gdpr.css';
@import 'assets/css/style.css';
@import 'assets/css/flags.css';
@import 'assets/css/jquery-yys-slider.css';
