html{overflow-x:hidden;  scroll-behavior: smooth;}
body {

	font-family:SF-Pro-Display-Regular, Helvetica, sans-serif;
	overflow:hidden;
	transition: background-color 1s ease;

}
@font-face {
font-family: 'SF-Pro-Display-Regular';
src: url('../fonts/SF-Pro-Display-Regular.otf') format('truetype');
font-weight: normal;
font-style: normal;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-smoothing: antialiased;
}
@font-face {
font-family: 'SF-Pro-Display-Black';
src: url('../fonts/SF-Pro-Display-Black.otf') format('truetype');
font-weight: normal;
font-style: normal;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-smoothing: antialiased;
}
@font-face {
font-family: 'SF-Pro-Display-Bold';
src: url('../fonts/SF-Pro-Display-Bold.otf') format('truetype');
font-weight: normal;
font-style: normal;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-smoothing: antialiased;
}
@font-face {
font-family: 'SF-Pro-Display-Heavy';
src: url('../fonts/SF-Pro-Display-Heavy.otf') format('truetype');
font-weight: normal;
font-style: normal;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-smoothing: antialiased;
}
@font-face {
font-family: 'SF-Pro-Display-Light';
src: url('../fonts/SF-Pro-Display-Light.otf') format('truetype');
font-weight: normal;
font-style: normal;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-smoothing: antialiased;
}
@font-face {
font-family: 'SF-Pro-Display-Medium';
src: url('../fonts/SF-Pro-Display-Medium.otf') format('truetype');
font-weight: normal;
font-style: normal;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-smoothing: antialiased;
}
@font-face {
font-family: 'SF-Pro-Display-Semibold';
src: url('../fonts/SF-Pro-Display-Semibold.otf') format('truetype');
font-weight: normal;
font-style: normal;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-smoothing: antialiased;
}
a {
	text-decoration:none;
	-moz-transition: all .3s ease-in-out;
 -webkit-transition: all .3s ease-in-out;
 transition: all .3s ease-in-out;
}
a:hover{text-decoration:none;}



.headertop{float:left; width:100%; background:#000; padding:12px 0px;}
.headertop-left{float:left; display:inline-block; font-size:14px; line-height:23px; color:#fff;}
.headertop-left a{color:#fff; font-size:17px; vertical-align:top;}
.headertop-left a:hover{color:#d7182a;}
.headertop-right{float:right; display:inline-block;}
.headertop-right ul{padding:0px; margin:0px;}
.headertop-right li{float:left; list-style:none; padding-left:22px;}
.headertop-right li a{font-size:15px; color:#fff;}
.headertop-right li a:hover{color:#e8e8e8;}
.headertop-right li a .fa{padding-right:10px;}
.langselect{ font-size:15px; color:#fff; border:none; outline:none; background:none; width:50px;}

.headerbottom{float:left; width:100%; padding:12px 0px; background:#fff;}
.navbar{padding:0px;}
.navbar-brand{padding:0px; margin:0px;}
.navbar-nav li{margin-left:28px; position:relative;}
.navbar-nav li a{color:#000; font-size:14px; padding-bottom:20px; font-family:SF-Pro-Display-Regular, Helvetica, sans-serif;}
.navbar-nav li a:hover{color:#d7182a;}


.signin{padding:10px 20px!important; border-radius:3px; color:#fff!important; background:#d7182a;}
.signin:hover{background:#fac259;}
.signin .fa{padding-right:10px;}

.navbar-nav > li .subnav{display:none; position:absolute; top:32px; width:500px; background:#fff; left:-40px; padding:15px; z-index:9; text-align:left; border-radius:5px;     box-shadow: 0px 0px 8px #ccc;}
.navbar-nav > li .subnavsmall{width:250px;}
.navbar-nav > li .subnavsmall ul{width:98%!important;}
.navbar-nav > li .subnav ul{float:left; width:48%; padding:0px; margin:0 1%;}
.navbar-nav > li ul li{padding:0px 0px; margin:0px; list-style:none;}
.navbar-nav > li ul li a:hover{text-decoration:none; color:#d7182a;}
.navbar-nav > li:hover .subnav{display:block;}
.subnav h3{font-size:15px; color:#d7182a; padding:0px; margin-top:0px;}


.bannerbg{padding:20px 0 20px 0px; float:left; width:100%;}
.bannertext{padding-top:25px;}
.bannertext h1{color:#434341; font-size:41px; letter-spacing:-.003em; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; line-height:48px; margin:0px; margin:0 0 15px 0px;}
.bannertext h1 sup{font-size:16px; top:-1.2em;}
.bannertext p{color:#333; font-size:21px; line-height: 35px; margin:0px;}
.bannertext h3{color:#176ca7; font-size:18px; margin:0px 0px 5px; font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif;}
.btnstyl1{display:inline-block; padding:10px 30px; border-radius:5px; background:#d7182a; color:#fff; font-size:16px; font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif;}
.btnstyl1:hover{background:#fac259; color:#fff;}

.btnstyl1A{display:inline-block; padding:10px 30px; border-radius:5px; background:#fff; color:#222; font-size:16px; font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif; border: 1px solid #11639e;}
.btnstyl1A:hover{background:#11639e; color:#fff;}



.bannerimg img{width:100%;}

.easy-integration-bg{padding:0px 0 40px 0px; float:left; width:100%;}

.easy-integration-toprow h1{color:#434341; font-size:27px; line-height:35px; text-align:center; margin-top:20px; margin-bottom:30px; font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif;}
.easy-integration-toprow-logo{text-align:center; padding:15px 15px 0px;}
.easy-integration-toprow-logo a{display:inline-block; margin:0px 10px; opacity:0.6;}
.easy-integration-toprow-logo a:hover{opacity:1;}

.easy-integration-midrow{padding:30px; background:#fff; border-radius:5px; margin:0px 0 35px 0px; width:100%; float:left;} 
.easy-integration-midrowtext h1{color:#434341; font-size:24px; line-height:35px; margin-top:10px; font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif;}
.easy-integration-midrowtext p{color:#434341; font-size:15px; margin:0px;}
.easy-integration-midrowtext p span{display:inline-block; padding-right:5px; vertical-align:text-bottom;}
.userlogos{text-align:center;}
.userlogos a{display:inline-block; margin:0px 15px;}

.easy-integration-bottomrow{padding:30px; background:#fff; border-radius:5px; margin:30px 0 15px 0px; box-shadow:0px 0px 15px #d7dee1; float:left; width:100%;} 
.easy-integration-bottomrow h2{display:inline-block; color:#434341; font-size:23px; font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif;}
.easy-integration-bottomrow p{color:#434341; font-size:15px; margin:0px; display:inline-block; float:right;}
.easy-integration-bottomrow p span{display:inline-block; padding-right:5px; vertical-align:text-bottom;}

.oneplatformrow{text-align:center; float:left; width:100%; padding-top:20px;}
.oneplatformrow ul{padding:0px; margin:0px;}
.oneplatformrow li{padding:0px 10px; float:left; width:20%; list-style:none;}
.oneplatformrow li span{display:block; color:#2f7fbe; font-size:15px; line-height:18px; margin-top:5px;}

.security-compliance{padding:25px 0px; float:left; width:100%;}
.security-compliance-content{padding-top:130px;}
.security-compliance-content h1{color:#434341; font-size:40px; line-height:48px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; margin:0 0 20px 0px;}
.security-compliance-content p{color:#434341; font-size:15px; line-height:20px; margin-bottom:20px;}
.security-compliance-img{text-align:right;}
.security-compliance-img img{max-width:100%;}

.convenience-dashboard-bg{float:left; width:100%; padding:15px 0px; background:url(../images/graybg.jpg) repeat; background-attachment:fixed; position:relative; margin: 30px 0px;}
.convenience-dashboard-content{padding-top:65px; padding-left:70px;}
.convenience-dashboard-content h1{color:#434341; font-size:40px; line-height:48px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; margin:0 0 20px 0px;}
.convenience-dashboard-content p{color:#434341; font-size:15px; line-height:20px; margin-bottom:20px;}
.convenience-dashboard-img img{max-width:100%;}

.go-digital-bg{float:left; width:100%;}
.go-digital-bg-inner{float:left; width:100%; background:url(../images/godigital-bg.jpg) repeat; position:relative;}
.apple-pay-bg-inner{float:left; width:100%; background:#000; position:relative;}

.go-digital-img img{width:100%;}
.go-digital-content{text-align:center; padding:80px 40px;}
.go-digital-content h1{color:#fff; font-size:40px; margin:0 0 25px 0px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif;}
.go-digital-content p{font-size:28px; line-height:35px; margin-bottom:25px; color:#fff;}
.apple-pay-content{text-align:center; padding:60px 130px; background:#000; height: 415px;}
.apple-pay-content h1{text-align:left; color:#fff; font-size:45px; font-weight: 600; margin:0 0 25px 0px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif;}
.apple-pay-content img{margin: 0 auto 40px;display: block;}



.app-card-bg{padding:60px 0px; float:left; width:100%;}
.app-card-tabs .nav-tabs{border:none;}
.app-card-tabs .nav-tabs li{padding-right:3px;}
.app-card-tabs .nav-tabs li a{color:#434341; font-size:15px; padding:12px 25px; text-align:center; background:#f0f4f4; display:block; border-radius:7px 7px 0px 0px; font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif;}
.app-card-tabs .nav-tabs li a.active{color:#fff; background:#d7182a;}

.app-card-content{padding:40px 0 0 0px;}
.app-card-content h2{color:#434341; font-size:33px; font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif; margin:0 0 15px 0px;}
.app-card-content p{color:#434341; font-size:20px; line-height:30px;}

.padd20{padding:40px 40px;}

.guide-bg{background:#f0f4f4; float:left; width:100%; padding:45px 0px; text-align:center; background:url(../images/graybg.jpg) repeat; background-attachment:fixed;}
.guide-bg p{color:#3e3f41; font-size:15px; line-height:20px; margin:10px 0 0 0px;}
.guidebox{margin:15px 0px; background:#fafafa; border-radius:10px; min-height:365px; padding:20px; box-shadow:0px 0px 25px #d3dddd;}
.guidebox h2{color:#2a2c2e; font-size:20px; font-family:SF-Pro-Display-Bold, Helvetica, sans-serif; margin:0 0 5px 0px;}

.guide-bg h1 {
    color: #434341;
    font-size: 34px;
    line-height: 35px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    font-family:SF-Pro-Display-Medium, Helvetica, sans-serif;
}


.most-trusted-brands{float:left; width:100%; padding:45px 0px;}
.most-trusted-brands h1{color:#434341; font-size:40px; line-height:48px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; text-align:center;}

.trusted-brand{margin-top:30px; background:#fff; padding:30px; border-radius:10px; border:solid 1px #ccc; height:390px;}
.trusted-brand-head{margin-bottom:12px;}
.trusted-brand-head img{width:85px; height:85px; float:left; margin-right:13px;}
.trusted-brand-head h4{color:#2a2a2a; font-size:20px; margin:0px; font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif;}
.trusted-brand-head .brandlogo{display:block; padding-top:10px;}
.trusted-brand-head .brandlogo img{width:auto; height:auto; float:none; margin-right:0px;}
.trusted-brand p{color:#3e3f41; font-size:15px; line-height:22px; margin:0px;}
.trusted-brand:hover {box-shadow: 0px 0px 5px #b7b7b7; cursor: pointer;}

.footer{float:left; width:100%; background:url(../images/footerbg.jpg) no-repeat; background-size:cover;}

.footertop{padding:60px 0 30px 0px; float:left; width:100%;}
.footertop h2{font-size:0.875rem; margin:0 0 15px 0px; color:#fff;}

.footerabout{width:30%; float:left;}
.footerabout p{color:#fff; font-size:0.75rem; line-height:21px; margin:0px;}

.startnowbtn{width:50px; height:50px; background:#d7182a; padding:12px 0px; border-radius:50%; line-height:50px; color:#fff; display:inline-block;}
.startnowbtn:hover{background:#fac259; color:#fff;}

.usefullinksbg{width:70%; float:left;}
.usefullinks{float:left; width:33%;}
.usefullinks ul{padding:0px; margin:0px;}
.usefullinks ul li{list-style:none;}
.usefullinks ul li a{color:#fff; font-size:0.75rem;}
.usefullinks ul li a:hover{color:#d7182a;}

.footertop-right{float:right; width:300px;}
.footersocial{display: flex; justify-content: center; align-items: center; width:100%; text-align:center; padding-bottom:25px;}
.footersocial a{display: flex; justify-content: center; align-items: center; background:#fff; height:40px; width:40px; text-align:center; line-height:40px; border-radius:50%; color:#d7182a; font-size:17px; padding:11px 0px; margin: 0px 5px;}
.footersocial a:hover{background:#d7182a; color:#fff;}

.footerbottom{padding:25px 0px; float:left; width:100%; color:#fff; font-size:0.75rem; text-align:center; border-top:solid 1px #fff;}


.overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
}

.overlay-content {
  position: relative;
  top: 46%;
  width: 80%;
  text-align: center;
  margin-top: 30px;
  margin: auto;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  cursor: pointer;
  color: white;
}

.overlay .closebtn:hover {
  color: #ccc;
}

.overlay input[type=text] {
  padding: 15px;
  font-size: 17px;
  border: none;
  float: left;
  width: 80%;
  background: white;
}

.overlay input[type=text]:hover {
  background: #f1f1f1;
}

.overlay button {
  float: left;
  width: 20%;
  padding:19px 15px;
  background: #0073b1;
  font-size: 17px;
  color:#fff;
  border: none;
  cursor: pointer;
}

.overlay button:hover {
  background: #025c8c;
}


.go-digital-bg-inner .play-area {
      position: absolute;
      z-index: 99;
      left:51.5%;
      top: 52%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50%; }
.go-digital-bg-inner .play-area a{color:#000;}
.go-digital-bg-inner .play-btn {
      background: #fff;
      height: 4rem;
      width: 4rem;
      border-radius: 100%;
      padding: 0.5rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: relative;
      z-index: 0;
      cursor: pointer; }
.go-digital-bg-inner .play-btn::before {
      content: "";
      position: absolute;
      bottom: 50%;
      right: 50%;
      border-radius: 100%;
      transform: translate(50%, 50%);
      background: rgba(255, 255, 255, 0.301);
      height: 150%;
      z-index: -1;
      animation-name: anim2;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0, 0, 0.41, 1.87);
      width: 150%; }
@keyframes anim-up-down {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  50% {
    -webkit-transform: translate(0, 1rem);
    transform: translate(0, 1rem); }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

.anim-up-down {
  animation-name: anim-up-down;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; }

@keyframes animbg {
  from {
    background-size: 130%;
    background-repeat: no-repeat;
    background-size: cover; }
  50% {
    background-size: 120%;
    background-blend-mode: saturation;
    background-repeat: no-repeat; }
  to {
    background-size: 130%;
    background-repeat: no-repeat; } }

@keyframes anim2 {
  from {
    height: 120%;
    width: 120%; }
  50% {
    height: 170%;
    width: 170%; }
  70% {
    height: 120%;
    width: 120%; }
  to {
    height: 130%;
    width: 130%; } }

@keyframes anim1 {
  from {
    height: 180%;
    width: 180%; }
  50% {
    height: 140%;
    width: 140%; }
  70% {
    height: 150%;
    width: 150%; }
  to {
    height: 180%;
    width: 180%; } }
	

.userlogos .item, .easy-integration-toprow .item{padding:10px 15px; border:solid 1px #ccc; text-align:center;}

.casesbox{text-align:center; margin-top:15px;}
.casesbox span{display:block; color:#2f7fbe; font-size:15px; line-height:18px; margin-top:5px;}

#fea-industries .owl-dots, #cases .owl-dots{margin-top:0px!important;}


.featured-in{float:left; width:100%; text-align:center; padding:30px 0px 0px;}
.featured-in h1{font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; color:#434341; font-size:40px; margin-bottom:30px;}
.featured-in ul{padding:0px; margin:0px;}
.featured-in li{display:inline-block; width:12%; border:solid 1px #ccc;}
.featured-in li a{padding:5px 10px; display:block;}
.featured-in li img{max-width:100%;}

.awardslogo{text-align:center; padding-bottom:20px; float:left; width:100%;}
.awardslogo p{font-size: 15px; color:#fff;}
.awardslogo ul{padding:0px; margin:0px;}
.awardslogo li{display:inline-block; padding:5px;}
.awardslogo li img{width:90px;}

.startlogos li{float:left; width:50%;}

.howitwork-bg{float:left; width:100%; text-align:center; background:#edf8f8; padding:50px 0px;}
.howitwork-bg h1{color:#434341; font-size:40px; line-height:45px; font-family:SF-Pro-Display-Bold, Helvetica, sans-serif; margin:0 0 20px 0px;}
.howitwork-bg p{color:#434341; font-size:15px; line-height:20px; margin-bottom:20px;}
.howitwork-bg ul{padding:0px; margin:0px;}
.howitwork-bg li{width:24%; display:inline-block; padding:30px; vertical-align:top;}
.howitwork-bg li span{display:inline-block; width:65px; height:65px; line-height:52px; font-size:25px; color:#fff; font-family:SF-Pro-Display-Bold, Helvetica, sans-serif; background:#d7182a; border-radius:50%; border:solid 6px #ccc; margin-bottom:15px;}

.key-benefits{float:left; width:100%; padding:50px 0 0 0px;}
.key-benefits h1{color:#434341; font-size:40px; line-height:45px; font-family:SF-Pro-Display-Bold, Helvetica, sans-serif; margin:0 0 40px 0px;}
.benefits-left-row ul{padding:0px; margin:0px;}
.benefits-left-row li{width:50%; background:url(../images/check-red.png) no-repeat left 5px; padding-left:30px; list-style:none; float:left; min-height:90px;}

.benifitbox{border-radius:10px; padding:80px 30px; text-align:center; background:#ccc; margin:15px 0px;}
.benifitbox-btn{display:inline-block; background:#d7182a; color:#fff; font-size:16px; padding:10px 15px; border-radius:5px;}
.benifitbox-btn:hover{background:#fac259; color:#fff;}

.loyalty-programmes{background:url(../images/loyalty-programme.jpg) no-repeat; background-size:cover; background-position:center;}
.giftcard{background:url(../images/giftcards.jpg) no-repeat; background-size:cover; background-position:center;}
.spicejet{background:url(../images/spicejet.jpg) no-repeat; background-size:cover; background-position:center; height: 200px;}

.benefits-leftpadd{padding-top:70px;}
.poweryowrwork-bg{float:left; width:100%; padding:50px 0 0 0px; position:relative; top:0px; text-align:center;}
.poweryowrwork-bg h2{display:inline-block; font-size: 35px!important; color: #434341; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif;}
.poweryowrwork-bg a{margin-left:20px; position:relative; top:-5px;}



/*100720*/

.bannertext02 {
    padding-top: 65px;
}
.bannertext02 h1 {
    color: #434341;
    font-size: 36px;
    font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif;
    line-height: 48px;
    margin: 0px;
    font-weight: 700;
    margin: 0 0 10px 0px;
    text-align: center;
}
.btnstyl102 {
    display: inline-block;
    padding: 10px 30px;
    border-radius: 5px;
    background: #d7182a;
    color: #fff;
    font-size: 16px;
    font-family: SF-Pro-Display-Semibold, Helvetica, sans-serif;
    margin: 0px 10px;
    position: relative;
    z-index: 1;
}


.btnstyl102:hover{background:#fac259; color:#fff;}

.btnstyl103{display:inline-block; padding:10px 30px; border-radius:5px; background:#11639e; color:#fff; font-size:16px; font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif; position: relative; z-index: 1;}
.btnstyl103:hover{background:#fac259; color:#fff;}

.btnshow{margin: 0px auto; display: table; padding: 20px 0px 0px;}
.befitbg{background: #dfefef!important;}
.section.is-sm {
    padding-top: 100px;
    padding-bottom:0px;
}
.bg-secondary {
    background: rgba(27, 170, 160, 0.08) !important;
}
.section {
    position: relative;
    z-index: 0;
}
.section .section-head {
    margin-bottom: 60px;
    width: 100%;
}
.section .section-title.is-center {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;        
}
.section .section-title {
    font-size: 35px!important;    
    color: #434341;
    text-align: left;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    font-family:SF-Pro-Display-Medium, Helvetica, sans-serif;
}
.section .section-description.is-center {
    text-align: center;
    font-size: 20px;
    color: #3b3c58;
    font-weight: 400;
}
.section .section-description {
    max-width: 40rem;
    text-align: left;
}
.section.section-steps .SimpleSteps {
    align-items: top;
    counter-reset: section;
    display: flex;
    justify-content: space-between;
    max-width: 970px;
    margin: 0 auto;
    position: relative;
}
.section.section-steps .SimpleSteps::before {
    background: rgba(0, 0, 0, 0.38);
    content: "";
    height: 1px;
    left: 140px;
    position: absolute;
    right: 120px;
    top: 23px;
    z-index: 1;
}
.section.section-steps .SimpleSteps__step {
    flex-basis: 0;
    flex-grow: 1;
    position: relative;
    text-align: center;
}
.section.section-steps .SimpleSteps__step::before {
    background: #11639e;
    border-radius: 50%;
    box-shadow: 0 0 0 10px white;
    content: counter(section);
    counter-increment: section;
    display: block;
    font-size: 1.5rem;
    height: 3rem;
    line-height: 3rem;
    margin: 0 auto 1rem;
    position: relative;
    width: 3rem;
    color: white;
    z-index: 2;
}
.section.section-steps .SimpleSteps__step-content {
    font-size: 1rem;
    font-weight: 400;
    color: #333;
}
.list-wrap {
    margin: 0 0 60px;
}
.list-wrap .title {
    margin-bottom: 10px;
    position: relative;
    padding-left: 2rem;
    color: #2f7fbe;
	font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif;
    font-size: 23px;
}
.list-wrap .title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(../images/check-icon.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 1.5rem;
    width: 1.5rem;
}
.list-wrap .desc {
    max-width: 25rem;
    padding-left: 2rem;
    color: #747474;
}
.toppadsss{margin-top: 50px;}

.cardimg{position:relative; float:left; margin-right:15px;}
.cardimg img{max-width:100%;}
.cardimg h4{position:absolute; top:45%; left:0px; text-align:center; width:100%;}

.worktxts{width: 100%; padding: 20px 0px; float: left;}
.worktxts span{width: 100%; text-align: center; font-size: 20px; color: #3b3c58; font-weight: 400; float: left;}
.worktxts a{color:#11639e; font-weight:600;}
.worktxts a:hover{color:#d7182a;}
.betnifixmdiv{width: 100%; float: left;}
.betnifixmdiv img{width: 100%; position: relative; float: left;}
.betnifixmdivbtn{position:absolute; left:0px; width:100%; top:40%;}

.banfitbtnsshow {    
    padding: 10px 20px;
    border-radius: 5px;
    background: #11639e;
    color: #fff;
    font-size: 16px;
    top: 0px;
    z-index: 1;
    display: table;
    margin: 0px auto;    
}
.banfitbtnsshow:hover {
    background: #fac259;
    color: #fff;
}
.box.has-style2 {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 3px 7px #0000000a;
    transition: .2s ease-in-out;
}
.box {
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    cursor: default;
}
.arrowshos{font-size: 12px!important; font-weight: 300!important; margin-left: 5px;}
.btndivss{width: 100%; float: left; position: absolute; bottom: 40px;}






.va-banner{position:relative; float:left; width:100%;}
.va-banner-img, .va-banner-img img{width:100%;}
.va-banner-content{position:absolute; left:0px; top:15%; width:100%; text-align:center;}
.va-banner-content h1 {
    color: #fff;
    font-size: 41px;
    font-family:SF-Pro-Display-Medium, Helvetica, sans-serif;
    line-height: 48px;
    margin: 0px;
    margin: 0 0 10px 0px;
    text-align: center;
}
.va-banner-content p{color:#fff; font-size:16px; line-height:1.38105; margin:0px;}

.apple-pay-landing-banner{position:relative;float:left;width:100%;height:430px;background:url(../images/applepay-landing-banner.jpg) no-repeat;background-size:100%;}
.apple-pay{padding:50px;text-align:center;}
.apple-pay .container{padding-bottom:50px;border-bottom:1.5px solid;}
.apple-pay h1{width:53%;font-weight:900;margin:0 auto 30px;font-family:SF-Pro-Display-Medium, Helvetica, sans-serif;}
.apple-pay h5{padding:0 310px;}

.apple-pay-landing{width:100%; text-align:center;}
.apple-pay-landing .container{padding-bottom:50px;border-bottom:1.5px solid;}
.apple-pay-landing ul{padding:0px; margin:0px;}
.apple-pay-landing li{display:inline-block; width:24%; vertical-align:top; margin:5px 0px; padding:20px; list-style:none; min-height:270px; text-align:left;}
.apple-pay-landing li h4 {font-weight:900;font-family:SF-Pro-Display-Medium, Helvetica, sans-serif;}
.apple-pay-landing li:first-child, .apple-pay-landing li:first-child{border:none;}
.apple-pay-landing li:nth-child(2){margin:5px 120px;}
.apple-pay-landing li p{font-weight: 400; margin:20px 0 0 0px;}
.apple-pay-red {color:#d7192a;font-weight:bolder;font-family:SF-Pro-Display-Medium, Helvetica, sans-serif;}
.apple-pay-text{font-weight: 400;font-size:20px; line-height:30px; color:#000;text-align:center;}
.apple-margin-top{margin-top: 40px;}

.apple-pay-info li{text-align: center;width:45%;margin:5px 20px !important;min-height: 0;}
.apple-pay-info li:nth-child(2){margin:0 !important;}

.apple-pay-steps img{width:250px}
.apple-pay-steps li p{height:100px;margin:0 !important;color: #000;}
.apple-pay-steps li{border:none !important;}

.apple-pay-footer{background:url(../images/apple-pay-bg.jpg) no-repeat; background-size:100%; width:100%; height:344px; text-align: center; color: #fff; padding: 60px 10px; margin-top: 50px;}
.apple-pay-footer h5{padding:10px 300px;}
.apple-pay-footer img{width: 250px;}

.apple-pay-faq{width:100%;margin-top: 50px;}
.apple-pay-faq .container{padding-top:50px;border-top:1.5px solid;}
.apple-pay-faq h2, .apple-pay-faq h5 {font-weight:900;font-family:SF-Pro-Display-Medium, Helvetica, sans-serif;}
.apple-pay-faq a{color:#d7192a;font-size: 20px;}
.apple-pay-faq ul{padding:0px; margin:0px;}
.apple-pay-faq li{display:inline-block; width:48%; vertical-align:top; margin:30px 0px; list-style:none; min-height:115px; text-align:left;padding-right:80px;}
.apple-pay-faq li p{font-weight: 400; margin:20px 0 0 0px;font-size: 20px;}

.howitbox{width:24%; padding:0px 20px; display:inline-block; vertical-align:top;}
.howitbox span{display:inline-block; padding:10px 0px; width:80px; height:80px; line-height:60px; background:#11639e; border:solid 10px #fff; border-radius:50%; margin-bottom:15px; position:relative;}
.howitbox span::after{width:300px; background:#11639e; height:2px; content:""; position:absolute; left:0px; top:30px; z-index:-9;}
.howitbox span img{width:35px;}
.howitbox p{color:#333; font-size:1rem; font-weight:400;}
.last::after{display:none!important;}

.storiesrow{float:left; width:100%;}
.storiesrow h2 {
    font-size: 30px;    
    color: #d7182a;
	margin-bottom:20px;
    text-align: left;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif;
}
.storiesrow p{color:#000; font-size:15px; font-family:SF-Pro-Display-Regular, Helvetica, sans-serif;}

.usecaseslink{padding:0px; margin:0px;}
.usecaseslink li{list-style:none; float:left; width:33%;}
.usecaseslink li a{color:#000; font-size:15px; padding-bottom:20px; font-family:SF-Pro-Display-Regular, Helvetica, sans-serif;}
.usecaseslink li a:hover{color:#d7182a;}

.howitworkbg{text-align:center;}
.howitworkbg ul{padding:0px; margin:0px;}
.howitworkbg li{display:inline-block; width:24%; vertical-align:top; padding:20px; list-style:none; border-left:solid 1px #ddebff; min-height:380px;}
.howitworkbg li:first-child, .howitworkbg li:nth-child(5){border:none;}
.howitworkbg li p{font-weight: 400; margin:20px 0 0 0px; font-size:20px; line-height:30px; color:#3b3c58;}

.key-benefits-bg{float:left; width:100%; text-align:center;}
.key-benefits-bg ul{padding:0px; margin:0px;}
.key-benefits-bg li{display:inline-block; width:24%; vertical-align:top; margin:5px 0px; padding:20px; list-style:none; border-left:solid 1px #ddebff; min-height:270px; text-align:left;}
.key-benefits-bg li:first-child, .key-benefits-bg li:nth-child(5){border:none;}
.key-benefits-bg li p{font-weight: 400; margin:20px 0 0 0px; font-size:20px; line-height:30px; color:#3b3c58;}

.usecases-bg{float:left; width:100%;}
.casesimgbox{position:relative; border-radius:10px; overflow:hidden; margin:15px 0px; background:#000;}
.casesimgbox img{width:100%; cursor:pointer;
-moz-transition: all .3s ease-in-out;
 -webkit-transition: all .3s ease-in-out;
 transition: all .3s ease-in-out;}
.casesimgbox:hover img{opacity:0.5;}
.casesimgbox a{display:block; bottom:17px; left:0px; width:100%; text-transform:uppercase; position:absolute; background:rgba(215, 24, 42, 0.8); padding:12px 18px; color:#fff; font-size:16px; font-weight:600;}
.casesimgbox a:hover{background:rgba(215, 24, 42, 1); color:#fff;}


/*060820*/

.test-boxes{width: 100%;  font-weight: normal!important; float: left;}
.test-boxes a{
    color: #3e3f41;
    font-size: 15px;
    line-height: 22px;
    margin: 0px;
    font-weight: normal!important;
    text-decoration: none;
    margin: 0em;    
    display: inline-block;    
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    float: left;
    white-space: normal;
    line-height: 22px;
}

.test-boxes, .first-link{
  -webkit-transition: 3.3s;
  -moz-transition: 3.3s;
  transition: 3.3s;     
  
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  transition-timing-function: linear;
}


.test-boxes{
  height: 225px;
  font: normal normal 700 1em/4em Arial,sans-serif;
  overflow: hidden;
  width: 100%;  
}

.test-boxes a:hover{
  text-decoration: none;
}

.first-link{
  margin-left: 0em;   
}

.test-boxes:hover .first-link{
  margin-top: -170px;
}



.banner-video{float:left; width:100%; position:relative; top:0px; left:0px; height:500px; overflow:hidden;}
#myVideo{position:relative; right:0; bottom:100px; vertical-align:top; width:100%; height:auto;}



.corporatebannerbg{background:#f5f5f5; position:relative; padding-bottom:0px;}
.corporatebannerbg::after{background:url(../images/corporate-after-img.png) no-repeat; background-size:100%; width:100%; height:60px; position:absolute; left:0px; bottom:-60px; content:"";}

.corporate-bottombg{background:#c4dcff; padding:100px 0 65px 0px; float:left; width:100%;}
.corporate-bottombg-box{border-radius:5px; padding:25px; background:#fff; margin-top:30px; float:left; width:100%; box-shadow:0px 13px 13px #a9c5ed; min-height:190px;}
.corporate-bottombg-box span{float:left; padding-right:20px;}
.corporate-bottombg-box h3{color:#464646; font-size:25px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif;}
.corporate-bottombg-box p{color:#434341; font-size:15px; line-height:20px; margin-bottom:0px;}

.corporate-key-benefits{float:left; width:100%; padding:50px 0px;}
.corporate-key-benefits h1{color:#434341; font-size:35px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; text-align:center; margin:0 0 35px 0px;}

.corporate-key-benefits-box{padding:15px; box-shadow:0px 0px 13px #e9e5e5; border-radius:5px; min-height:365px; margin-bottom:30px;}
.corporate-key-benefits-box h3{color:#464646; font-size:20px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; margin-top:15px;}
.corporate-key-benefits-box p{color:#434341; font-size:16px; line-height:22px; margin-bottom:0px;}

.usecase-bg-inner{background: url(../images/usecasebg.jpg) repeat;}
.headingsty02{color:#434341; font-size:35px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; text-align:center; margin:0 0 35px 0px;}
.usecase-bg-inner-content{padding:40px 50px; text-align:center;}
.usecase-bg-inner-content h1{color:#000; font-size:35px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; text-align:center; margin:0px;}
.usecase-bg-inner-content h2{color:#000; font-size:22px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; margin:10px 0 0 0px;}
.usecase-bg-inner-content h4{color:#000; font-size:20px; line-height:26px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; margin:0 0 15px 0px;}
.usecase-bg-inner-content h3{color:#000; font-size:25px; font-family:SF-Pro-Display-Bold, Helvetica, sans-serif; margin:0 0 15px 0px;}
.usecase-bg-inner-content ul{padding:0px; margin:0px;}
.usecase-bg-inner-content li{list-style:none; font-size:16px; background:url(../images/benifitdot.png) no-repeat left 4px; color:#000; padding-left:25px; text-align:left;}

.testiheadings{text-align:center;}
.testiheadings h2{color:#434341; font-size:35px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; text-align:center; margin:0px;}
.testiheadings h3{color:#1f1f1f; font-size:20px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; margin:20px 0px;}

.xpensiologo{width:250px;}

.corporate-bottombg-box-big span{padding-bottom:130px;}
.corporate-bottombg-box-big02 span{padding-bottom:60px;}

.homeread {display: inline-block; padding: 10px 30px; border-radius: 5px; background: #d7182a; color: #fff; font-size: 16px; font-family: SF-Pro-Display-Semibold, Helvetica, sans-serif;}
.homeread:hover { background: #fac259; color: #fff;}

/*270820*/


.mediatopbannerbg{background:#f5f5f5; position:relative; padding-bottom:0px; z-index: 0;}
.mediatopbannerbg::after{background:url(../images/corporate-after-img.png) no-repeat; background-size:100%; width:100%; height:60px; position:absolute; left:0px; bottom:-60px; content:"";}
.media-banner-box{border-radius:5px; padding:25px 0px 0px; background:#fff; margin-top:30px; float:left; width:100%; box-shadow:0px 13px 13px #a9c5ed; margin: -250px 0px -80px; z-index: 1; position: relative;}
.mediabenertop{width: 100%; float: left; text-align: center; padding-bottom: 25px;}
.mediabenertop span{color: #454545; font-size: 16px; margin: 0px auto; display: table; border-bottom: solid 2px #ffd88e; font-family: 'SF-Pro-Display-Regular'; padding: 10px 15px;}
.media-banner-box h3{color:#1f1f1f; padding: 0% 18%; font-size:17px; font-family: 'SF-Pro-Display-Bold'; float: left; width: 100%; text-align: center; line-height: 28px;}
.media-banner-box p{color:#1f1f1f; padding: 0% 14%; font-size:17px; line-height:20px; margin-bottom:0px; text-align: center; font-family: 'SF-Pro-Display-Regular'; width: 100%; line-height: 24px; float: left;}
.mediabtns {display: inline-block; padding: 10px 11px 10px 20px; border-radius: 5px; background: #d7182a; color: #fff; font-size: 17px; font-family: SF-Pro-Display-Semibold, Helvetica, sans-serif; margin: 30px 0px 15px;}
.mediabtns:hover {background: #fac259; color: #fff;}
.btncions{padding-left: 10px;}
.medialogoinfo{width: 100%; background: #f8f8f8; padding: 15px 0px 25px; border-radius: 0px 0px 5px 5px; float: left;}
.medialogoinfo ul{width: 100%; padding: 0px; margin: 0px; text-align: center; float: left;}
.medialogoinfo ul li{padding: 10px 25px; list-style-type: none; display: inline-block;}
.medialogoinfo ul li a{display: inline-block;}

.media-bottombg{padding:100px 0 65px 0px; float:left; width:100%;}
.media-relasttop{width: 100%; padding: 50px 0px 30px; float: left;}
.media-relasttop h4{color:#464646; font-size:30px; font-family: 'SF-Pro-Display-Bold', sans-serif; width: 100%; float: left; text-align: center;}
.mediaboxshow{border-radius:5px; background:#fff; margin-top:40px; float:left; width:100%;}
.medeaboxinss{padding:25px 32px; background: #edf5ff; color: #727a84; float:left; width:100%; min-height: 175px; text-align:center;}
.medeaboxinss:hover{color: #1f1f1f; background: #fff; float:left; width:100%; min-height: 160px;}
.medeaboxinss h3{font-size:15px; font-family: 'SF-Pro-Display-Bold', sans-serif; text-align: center; width: 100%; float: left;}
.medeaboxinss p{font-size:15px; color: #505050; line-height:20px; margin-bottom:0px; width: 100%; text-align: center; float: left;}
.realesimgdiv{width: 100%; float: left;}
.realesimgdiv img{width: 100%; border-radius: 5px 5px 0px 0px; float: left;}

.padinginfo{width: 100%; padding: 50px 0px 100px; float: left;}
.padinginfo ul{width: 100%; padding: 0px; margin: 0px; text-align: center; float: left;}
.padinginfo ul li{list-style-type: none; display: inline-block; padding: 0px 5px;}
.padinginfo ul li a{display: inline-block; background: #fcfcfc; border-radius: 5px; padding: 10px 20px; color: #6a6e74; font-size: 17px; font-family: 'SF-Pro-Display-Semibold';}
.padinginfo ul li a:hover{background: #d7182a; color: #fff;}
.padinginfo ul li a.pageactives{background: #d7182a; color: #fff;}

.padabout{padding: 50px 0px 0px!important;}
.aboutbannertxt{padding-top:25px; width: 100%; float: left; padding: 40px 50px 0px 0px; display: block!important;}
.aboutbannertxt h1{color:#1f1f1f; font-size:45px; letter-spacing:-.005em; line-height:48px; margin:0 0 20px 0px; display: block!important;}
.aboutbannertxt p{color:#1f1f1f; font-size:15px; line-height: 24px; margin:0px; width: 86%; float: left;}
.aboutbannre{width:100%; position: relative; z-index: 1;  float: left; }
.aboutbannre img{width:100%; float: left; }


.padaward{padding: 10px 0px 50px;}
.awardbox{width: 100%; box-shadow: 0px 5px 10px #a9c5ed; border-radius: 5px; padding: 10px 15px; text-align: center; float: left; background: #fff;}

.aboutinfoitem{width: 100%; margin: 25px 0px 50px; float: left;}
.aboutinfomatter{width: 100%; float: left;}
.aboutinfomatter h1{color:#1f1f1f; font-size:45px; letter-spacing:-.005em; font-family: 'SF-Pro-Display-Bold', sans-serif; line-height:48px; margin:0px; margin:0 0 20px 0px; float: left; width: 100%;}
.aboutinfomatter h4{color: #1f1f1f; font-size: 30px; font-family: SF-Pro-Display-Medium, Helvetica, sans-serif; letter-spacing: -.005em; margin: 0px; margin: 10px 0px 25px; float: left; width: 100%; line-height: 45px;}
.aboutinfomatter h5{color:#1f1f1f; font-size:25px; margin:0px; margin:10px 0px 20px; float: left; width: 100%;}
.aboutinfomatter p{color:#1f1f1f; font-size:15px; line-height: 24px; margin:0px 0px 10px; width: 95%; float: left;}
.aboutshwinfoimg{width: 100%; padding-right: 15px; margin-bottom: 30px; float: left;}
.aboutshwinfoimg img{width: 100%; border-radius: 10px; float: left;}
.doticonss{font-size: 14px; color: #d7182a; margin-right: 5px;}
.aboutpobtn {display: inline-block; padding: 10px 11px 10px 20px; border-radius: 5px; background: #d7182a; color: #fff; font-size: 17px; font-family: SF-Pro-Display-Semibold, Helvetica, sans-serif; margin: 10px 0px;}
.aboutpobtn:hover {background: #fac259; color: #fff;}

.our-janouryinfomain{width: 100%; padding: 50px 0px 100px; float: left;}
.our-janouryinfomain h1{color:#1f1f1f; font-size:45px; letter-spacing:-.005em; font-family: 'SF-Pro-Display-Bold', sans-serif; line-height:48px; margin:0 0 50px 0px; text-align: center; width: 100%; float: left;}

.celendershowinner{float: left; width: 100%;}
.leftcelenderdiv{float: left; padding-bottom: 70px; margin-top: 100px; width: 100%; position: relative;}
.leftcelenderdiv h2{float: left; text-align: right; padding:0px 30px 70px 0px; width: 100%; font-size: 25px; font-family: 'SF-Pro-Display-Bold', sans-serif; color: #d7182a; margin-top: -110px;}


.yearlydatetxt{width: 100%; margin-left: 11px; position: relative; z-index: 99; float: left;}
.yearlydatetxt span{font-size: 15px; font-family: SF-Pro-Display-Semibold, Helvetica, sans-serif; background: #feede6; color: #9d928d; padding: 3px 10px; float: left;}
.padmob{padding: 0px;}

.rightcelenderdiv{float: left; position: relative; width: 100%; margin-top: 100px;}
.rightcelenderdiv h2{float: left; text-align: left; padding:0px 0px 70px 30px; width: 100%; font-size: 25px; font-family: 'SF-Pro-Display-Bold', sans-serif; color: #d7182a; margin-top: -110px;}
.rightcelenderdiv::before {border-left: solid 2px #9d928d;content: ""; height: 100%; position: absolute; left: -25px;}
.yearlydatetxt02{width: 100%; margin-left: -36px; float: left;}
.yearlydatetxt02 span{font-size: 15px; font-family: SF-Pro-Display-Semibold, Helvetica, sans-serif; background: #feede6; color: #9d928d; padding: 3px 10px; float: left;}
.yearpad{padding:0px;}


.leftpartyear{width: 100%; padding: 15px 0px 20px 0px; float: left;}
.leftpartyear p{width: 100%; font-size: 16px; text-align: right; color:#1f1f1f; line-height: 22px; margin-bottom: 10px; float: left; padding-right: 15px;}
.leftmonthhead{width: 100%; text-align: right; margin-bottom: 10px; font-size: 18px;  color:#1f1f1f; font-family: 'SF-Pro-Display-Bold', sans-serif; float: left; margin-right: -25px;}
.mothtsxt{width: 100%; font-size: 18px; text-decoration: line-through; color:#1f1f1f; float: left; margin-bottom: 10px;}
.leftpad{padding: 160px 0px 0px;}
.leftpad02{padding: 200px 0px 0px;}
.leftpad03{padding: 50px 0px 0px;}
.leftpad04{padding: 20px 0px 0px;}
.leftpad05{padding: 80px 0px 0px;}
.leftpad06{padding: 50px 0px 0px;}


.rightboxyear{width: 100%; padding: 15px 0px 20px 0px; float: left;}
.rightboxyear p{width: 100%; font-size: 16px; color:#1f1f1f; line-height: 22px; float: left;}
.monthheads{width: 100%; margin-bottom: 10px; font-size: 18px; text-decoration: line-through; color:#1f1f1f; font-family: 'SF-Pro-Display-Bold', sans-serif; float: left; margin-left: -25px;}
.monthheads02{width: 100%; margin-bottom: 10px; font-size: 18px; color:#1f1f1f; font-family: 'SF-Pro-Display-Bold', sans-serif; float: left; margin-left: -25px;}
.mothtsxt{width: 100%; font-size: 18px; text-decoration: line-through; color:#1f1f1f; float: left; margin-bottom: 10px;}
.rightpad{padding: 425px 0px 0px;}
.rightpad01{padding: 200px 0px 0px;}
.rightpad02{padding: 50px 0px 0px;}
.rightpad03{padding: 50px 0px 0px;}
.rightpad04{padding: 30px 0px 0px;}
.rightpad05{padding: 130px 0px 0px;}
.rightpad06{padding: 355px 0px 0px;}

.logojonary {width: 20px; display: block; float: left; margin: 5px 0px 0px 17px;}
.logojonary img {width: 100%;}
.logojonary02 {width: 20px; display: block; float: left; margin: 5px 0px 0px 18px;}
.logojonary02 img {width: 100%;}


.aboutoverimg01 {position: relative; margin: 30px 0px 20px; width: 100%;}
.aboutoverimg01 img {opacity: 1; display: block;width: 100%; height: auto; transition: .5s ease; backface-visibility: hidden; border-radius: 10px;}
.aboutoverimg01:hover .image {opacity: 0.3;}
.aboutoverimg01:hover .middle {opacity: 1;}
.middle {transition: .5s ease; opacity: 0; position: absolute; bottom: 25px; text-align: center; left: 0px;}
.abouthovecont{width: 100%; float: left; background: rgb(255,255,255, .9); padding: 5px 10px; cursor: pointer;}
.abouthovecont p{width: 100%; float: left; color: #121212; font-size: 16px; line-height: 22px; padding-bottom: 0px; margin-bottom: 0px;}
.padaoboutss{padding-left: 50px;}
.padimgab01{margin:98px 0px 25px;}
.padlefcont{padding-left: 15px;}




/***31Aug***/


.subtrabannerbg{min-height:500px;}
.subtrabannerimg{position:absolute; right:0px; top:0px; z-index:9; width:755px;}
.subtrabannerimg img{max-height:100%;}

.oursolutionbg{background:url(../images/oursolutionbg.jpg) repeat; margin:25px 0px;}
.oursolutionbg h1{color:#434341; font-size:35px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; text-align:left; margin:0 0 20px 0px;}
.oursolutionbg p{color:#333; font-size:16px; line-height: 24px; margin-bottom:7px; text-align:left;}

.ourproductsbg{float:left; width:100%; padding:85px 0px; position:relative;}

.btomscroll-position {
    position: absolute!important;
    bottom: -30px;
    left: 0;
    top: inherit!important;
    z-index: 999!important;
}
.ourproductsbg h1{color:#434341; font-size:35px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; text-align:center; margin:0 0 55px 0px;}

.ourproductsbox{min-height:460px; padding:25px 35px; border-radius:5px; background:#fff;}

.navbar-nav > li .subnav{z-index:99;}


/*010920*/

.bannershow{width: 100%; padding-top: 15px; float: left;}
.bannershow ul{width: 100%; padding:0px; margin: 0px 0px 0px -25px; float: left;}
.bannershow ul li{float: left; list-style-type: none; width: 25%;}
.bannershowbox{width: 100%; text-align: center; float: left;}
.bannershowbox h3{width: 100%; margin: 10px 0px 0px; font-family:SF-Pro-Display-Medium, Helvetica, sans-serif; font-size: 40px; color: #000; float: left;}
.bannershowbox span{width: 100%; margin: 0px; font-size: 17px; color: #000; float: left;}
.banericons{background: #fff; border-radius: 50px; padding-top: 15px; width: 83px; height: 86px; margin: 0px auto; text-align: center; display: table;}
.padiocn{padding-top: 20px!important;}
.anentinfostrip{width: 100%; padding: 0px 0px 30px; float: left;}
.anentinfostrip h4{color:#464646; font-size:30px; font-family: 'SF-Pro-Display-Bold', sans-serif; width: 100%; float: left; margin-bottom: 50px; text-align: center;}
.angentlogos{width: 100%; float: left;}
.angentlogos ul{width: 100%; padding:0px; margin: 0px; text-align: center; list-style-type: none; float: left;}
.angentlogos ul li{width: 25%; display: inline-block; margin: 0px 10px;}

.busneisaboutext{width: 100%; padding: 0% 11%; margin-bottom: 100px; float: left;}
.busneisaboutext h4{color:#464646; font-size:30px; font-family: 'SF-Pro-Display-Bold', sans-serif; width: 100%; float: left; margin-bottom: 30px; text-align: center;}
.busneisaboutext p{width: 100%; color: #1f1f1f; text-align: center; font-size: 16px; line-height: 24px; float: left;}

.matchmoveculsarinfo{width: 100%; padding: 0px; margin: 100px 0px; float: left;}
.matchmoveculsarinfo h4{color:#464646; font-size:30px; font-family: 'SF-Pro-Display-Bold', sans-serif; width: 100%; float: left; margin-bottom: 30px; text-align: center;}

.matchcluarmatter{width: 100%; float: left;}
.padmter{padding: 50px 0px 0px;}

.viewpotionbtn{padding:10px 30px; border-radius:5px; background:#d7182a; color:#fff; font-size:16px; font-family:SF-Pro-Display-Semibold, Helvetica, sans-serif; float: right; margin: 20px 0px 0px;}
.viewpotionbtn:hover{background:#fac259; color:#fff;}




/*****02-09*****/

.lightspeedbanner{background:#f0f3f4; float:left; width:100%; position:relative; padding-top:45px;}
.lightspeedbanner::after{background:url(../images/lightspeedafter.jpg) no-repeat; background-size:100%; height:105px; width:100%; position:absolute; bottom:-105px; left:0px; content:"";}

.lightspeedbanner-text{padding-top:125px;}
.lightspeedbanner-text h3{color:#d7182a; font-size:29px; margin:0px; font-weight:500;}
.lightspeedbanner-text h1{color:#11639e; font-size:50px; font-weight:700; margin:25px 0 15px 0px;}
.lightspeedbanner-text p{color:#626a6f; font-size:32px; line-height:42px;}
.lightspeedbanner-text a{display:inline-block; padding:10px 30px; border-radius:5px; background:#eebd60; color:#d7182a; font-size:19px; font-weight:600;}
.lightspeedbanner-text a .fa{padding-left:18px;}
.lightspeedbanner-text a:hover{background:#d7182a; color:#fff;}

.lightspeedbanner-img img{max-width:100%;}

.growingrow{padding:200px 0 125px 0px; float:left; width:100%; text-align:center;}
.growingrow h1{font-size:37px; color:#d94d52; margin:0 0 15px 0px;}
.growingbox{margin-top:30px;}
.growingbox img{max-width:100%;}
.growingbox h2{color:#f15b67; font-size:70px; font-weight:700;}
.growingbox p{color:#000; font-size:17px; text-transform:uppercase; font-weight:700;}


.digitalcashbg{background:url(../images/digitalcash-bg.jpg) no-repeat left bottom #f8f8f8; background-size:100%; padding:220px 0px; float:left; width:100%;}
.digitalcashbg h1{font-size:37px; color:#d94d52; margin:0 0 5px 0px;}
.digitalcashbg ul{padding:45px 0 0 0px; margin:0px; width:50%;}
.digitalcashbg ul li{margin-bottom:30px; list-style:none; color:#181818; font-size:17px; line-height:24px;}
.digitalcashbg ul li span{float:left; width:55px; text-align:center; margin-right:13px;}

.caseandclientbg{padding:65px 0px; float:left; width:100%;}
.caseandclientbg h1{font-size:37px; color:#d94d52; margin:0 0 80px 0px; text-align:center;}

.caseandclienttabs .nav-link{color:#11639e; font-size:17px; padding:15px 30px; margin:1px 0px;}
.caseandclienttabs .nav-pills .nav-link.active, .caseandclienttabs .nav-link:hover{background:#d94d52; color:#fff;}
.caseandclienttabs .tab-pane{padding-left:50px;}
.caseandclienttabs .tab-pane p{color:#34393e; font-size:17px; line-height:24px; margin-bottom:25px;}

.partnersandlicenses{float:left; width:100%; background:url(../images/partnersandlicensesbg.png) no-repeat top center #f8f8f8; background-size:100%; padding:110px 0 60px 0px; text-align:center;}
.partnersandlicenses h1{font-size:37px; color:#11639e; margin:0 0 30px 0px;}
.partnersandlicenses ul{padding:0px; margin:0px;}
.partnersandlicenses li{display:inline-block; padding:10px; background:#fff; border:solid 1px #ccc; margin:10px;}

.opportunitybg{float:left; width:100%; padding:55px 0 100px 0px; position: relative; z-index: -1; background:url(../images/opportunitybg.jpg) no-repeat center bottom #e64c51; background-size:100%; text-align:center;}
.opportunitybg h1{color:#f8f8f8; font-size:37px; font-weight:400; margin:0 0 25px 0px;}
.opportunitybg h5{color:#f8f8f8; font-size:22px; font-weight:400; margin:0 0 45px 0px;}
.opportunitybg ul{padding:0px; margin:0px;}
.opportunitybg li{display:inline-block; width:250px; height:250px; border:solid 10px #ff5d63; border-radius:50%; color:#fff; font-size:25px; line-height:32px; padding:75px 30px; vertical-align:top; margin:15px; position:relative;
background: rgb(250,80,87);
background: linear-gradient(0deg, rgba(250,80,87,1) 0%, rgba(255,93,99,1) 45%, rgba(250,80,87,1) 100%);}
.opportunitybg li::after{background:url(../images/opportunitybg-shadow.png) no-repeat; width:345px; height:66px; bottom:-70px; position:absolute; content:""; left:-70px; opacity: 0.3;}
.opportunitybg-bottomtext{padding:0px 45px; color:#fff; font-size:17px; line-height:24px; margin:60px 0 0 0px;}

.currentchallenges-bg{padding:75px 0px; z-index: 5; margin-top: -5px; background: #fff; float:left; width:100%;}
.currentchallenges-bg h1{color:#11639e; font-size:37px; font-weight:400; margin:0 0 55px 0px; text-align:center;}

.integrationbox{padding:25px; box-shadow:0px 0px 35px #eae6e6; min-height:190px;}
.integrationbox h3{color:#e0646c; font-size:25px; margin:0 0 17px 0px;}
.integrationbox ul{padding:0px; margin:0px;}
.integrationbox li{list-style:none; padding-left:25px; color:#34393e; font-size:17px; line-height:24px; background:url(../images/bluedot.png) no-repeat left 5px;}
.integrationbox li.pink{background:url(../images/pinkdot.png) no-repeat left 5px;}

.integrationimgbox img{max-width:100%;}

.deliverablesbox li{margin:10px 0px;}
.deliverablesbox li{color:#11639e;}
.deliverablesbox li.pink{color:#ff4a51;}
.deliverablesbox li span{display:block; color:#7a8086;}


.lightspeed-3stepsbg{float:left; position: relative; z-index: 9; background: #fff; margin-bottom: -4px; width:100%; padding:100px 0px;}
.lightspeed-3stepsbg h1{color:#d94d52; font-size:37px; margin:0px;}
.lightspeed-3stepsbg h1 span{color:#11639e;}
.lightspeed-stepsrow{position:relative; float:left; width:100%; min-height:580px;}
.lightspeed-stepsimg{position:absolute; right:0px; top:0px;}

.lightspeed-stepscontent{position:relative; padding:25px; box-shadow:0px 0px 35px #eae6e6; margin-top:140px; border:solid 1px #ececec; width:50%;}
.lightspeed-stepscontent .stepno{position:absolute; right:0px; top:-80px;}
.lightspeed-stepscontent h3{color:#11639e; font-size:25px; margin:0 0 15px 0px; font-weight:300;}
.lightspeed-stepscontent p{color:#34393e; font-size:17px; line-height:25px; margin:0px;}
.lightspeed-stepscontent p span{float:left; margin:5px 10px 22px 0px;}


.lightspeed-stepsrow-pink .lightspeed-stepscontent{float:right;}
.lightspeed-stepsrow-pink .lightspeed-stepsimg{left:0px; right:inherit;}
.lightspeed-stepsrow-pink h3{color:#d94d52;}

.agentprotalbg{float:left; width:100%; padding:100px 0px; text-align:center;
background-image: url(../images/agentportal-bottombg.jpg), url(../images/agentportal-topbg.jpg);
background-position: left bottom, left top;
background-repeat: no-repeat, no-repeat;
background-size:100%;
background-color:#11639e;
position: relative;
z-index: 0;
}

.agentprotalbg h1{color:#fff; font-size:37px; margin:0px;}
.agentprotal-imgbox{margin-top:30px;}
.agentprotal-imgbox img{max-width:100%;}
.agentprotal-imgbox p{color:#fff; font-size:17px; line-height:24px; margin:0px; padding:0px 30px;}

.whatnextbg{float:left; width:100%; background: #fff; position: relative; z-index: 5; margin-top: -8px; text-align:center; padding:45px 0px;}
.whatnextbg h1{color:#d94d52; font-size:37px; margin:0 0 40px 0px;}
.whatnextbg ul{padding:0px; margin:0px;}
.whatnextbg ul li{display:inline-block; background:url(../images/rightarrow-pink.png) no-repeat left center; padding-left:35px; color:#34393e; font-size:17px; text-transform:uppercase;}

.clientsbg{float:left; width:100%; padding:55px 0px; text-align:center;
background-image: url(../images/clientsbottombg.jpg), url(../images/clientstopbg.jpg);
background-position: left bottom, left top;
background-repeat: no-repeat, no-repeat;
background-size:100%;
background-color:#f8f8f8;
}
.clientsbg h1{color:#11639e; font-size:37px; margin:0 0 40px 0px; text-align:center;}

.partnersrow{margin-top:40px;}
.partnersrow ul{padding:0px; margin:0px;}
.partnersrow li{display:inline-block; padding:10px; background:#fff; border:solid 1px #ccc; margin:10px;}
.clientsrow .item{background:#fff;}

.media-coverage-bg{padding:65px 0px; float:left; width:100%; text-align:center;}
.media-coverage-bg h1{color:#11639e; font-size:37px; margin:0 0 40px 0px;}
.media-coverage-bg h5{color:#11639e; font-size:22px; line-height:31px; margin:0 0 35px 0px;}

.lightspeed-reviews-bg{background:url(../images/lightspeed-reviews-bg.jpg) no-repeat #f2f2f2; background-size:100%; float:left; width:100%; padding:90px 0px; position:relative;}
.lightspeed-reviews-content p{text-align:right; margin-top:30px;}
.lightspeed-reviews-content h4{color:#e0646c; font-size:45px; line-height:53px; margin:80px 0 0 0px; text-align:center;}
.lightspeed-reviews-img{text-align:center;}
.lightspeed-reviews-content, .lightspeed-reviews-img img{max-width:100%;}

.owl-theme .owl-dots .owl-dot{outline: none!important;}
.padpater{padding: 10px 0px 30px!important;}



/***/

.partnersandlicenses li img, .partnersrow li img{width:140px;}
.authorized li img{width:auto;}
.caseandclienttabs .tab-pane p span{display:inline-block; margin-right:10px;}

#integrationlogos{text-align:center;}
.integrationlogosbox{display:inline-block; border: solid 1px #ccc; padding:10px 30px; margin:2px 15px;}

.text03{text-transform:uppercase; font-weight:600; color:#000; font-size:15px; letter-spacing:2px; margin-bottom:25px;}

.singlemedia{float:left; width:100%; padding:50px 0 0 0px;}
.singlemedia h5{margin-top:40px; font-weight:600; color:#d7182a;}
.singlemedia h1{font-size:30px; font-weight:600; margin-bottom:20px;}
.singlemedia h3{font-size:23px; font-weight:600;}
.singlemedia p img{max-width:100%;}


.country_selector {
   cursor: text;
}
.country_selector {
   width: 400px;
   float: none;
   margin: auto;
   display: inline-block;
   text-align: left;
}

.country_selector.single .selectize-input:after {
   display: none !important;
}

.country_selector .selectize-input .item .flag-icon, .country_selector .selectize-dropdown .option .flag-icon{
   margin-right: 10px;
}

.country_selector.single .selectize-input, .country_selector.single .selectize-input input {
   cursor: text !important;
}

.country_selector .selectize-dropdown .option {
   cursor: pointer !important;
}

.country_selector {
   cursor: text;
}
.country_selector {
   width: 400px;
   float: none;
   margin: auto;
   display: inline-block;
   text-align: left;
}
 
.country_selector.single .selectize-input:after {
   display: none !important;
}
 
.country_selector .selectize-input .item .flag-icon, .country_selector .selectize-dropdown .option .flag-icon{
   margin-right: 10px;
}


.privacypage{float:left; width:100%;}
.privacypage h3{font-family: 'SF-Pro-Display-Bold', sans-serif; font-size:20px; color:#d7182a; margin:0 0 20px 0px;}
.privacypage p{color:#1f1f1f; font-size:16px; line-height:24px; float:left; width:100%;}

.contactbg{float:left; width:100%; padding-top:30px;}

.contact-from .form-group{margin:10px 0px;}
.contact-from .form-group label{color:#000; font-size:16px; width:100%;}
.contact-from .form-group label span{color:#e80404;}
.contact-from .form-control{font-size:15px; color:#000; padding:0px 17px; height:46px; border:none; background:#f3f3f4; border-radius:7px; box-shadow:inset 0 3px 7px rgba(0,0,0,.0);}
.contact-from .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #000;
}
.contact-from .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #000;
}
.contact-from .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #000;
}
.contact-from .form-control:-moz-placeholder { /* Firefox 18- */
  color: #000;
}
.contact-from .custom-checkbox label{font-size:15px;}
.contact-from .custom-checkbox label a{color:#0e59a5;}
.contact-submitbtn{display:inline-block; color:#fff; border:none; font-size:17px; border-radius:7px; padding:10px 40px; background:#d7182a;}
.contact-submitbtn:hover{background:#a60817;}

.map{position:relative; margin-top:40px;}
.mapicon{position:absolute; left:20px; top:0px;}

.mapicongif{position:absolute; left:52%; bottom:13%;}
.mapicongif a{position:relative;}
.mapicongif a img{width:50px;}


.mapiconhover{background:rgba(255, 255, 255, 0.7); padding:15px; border-radius:5px; text-align:center; display:none; position:absolute; width:300px; left:-120px; bottom:30px;}
.mapiconhover p{color:#000;}
.mapicongif a:hover .mapiconhover{display:block;}
.mapiconhover h3{font-family: 'SF-Pro-Display-Bold', sans-serif; font-size:20px; color:#d7182a; margin-top:10px;}
.mapiconhover p{margin:0px;}

.mapimg img{width:100%;}



/*051020*/


.mapicongif01{position:absolute; left:0%; bottom:53%;}
.mapicongif01 a{position:relative;}
.mapicongif01 a img{width:50px;}

.mapiconhover01{background:rgba(255, 255, 255, 0.7); padding:15px; border-radius:5px; text-align:center; display:none; position:absolute; width:200px; left:10px; bottom:30px;}
.mapiconhover01 p{color:#000;}
.mapicongif01 a:hover .mapiconhover01{display:block;}
.mapiconhover01 h3{font-family: 'SF-Pro-Display-Bold', sans-serif; font-size:20px; color:#d7182a; margin-top:10px;}
.mapiconhover01 p{margin:0px;}



.mapicongif02{position:absolute; left:7%; bottom:39%;}
.mapicongif02 a{position:relative;}
.mapicongif02 a img{width:50px;}

.mapiconhover02{background:rgba(255, 255, 255, 0.7); padding:15px; border-radius:5px; text-align:center; display:none; position:absolute; width:200px; left:-20px; bottom:30px; z-index:99;}
.mapiconhover02 p{color:#000;}
.mapicongif02 a:hover .mapiconhover02{display:block;}
.mapiconhover02 h3{font-family: 'SF-Pro-Display-Bold', sans-serif; font-size:20px; color:#d7182a; margin-top:10px;}
.mapiconhover02 p{margin:0px;}



.mapicongif03{position:absolute; left:17%; bottom:50%;}
.mapicongif03 a{position:relative;}
.mapicongif03 a img{width:50px;}

.mapiconhover03{background:rgba(255, 255, 255, 0.7); padding:15px; border-radius:5px; text-align:center; display:none; position:absolute; width:200px; left:-50px; bottom:30px;}
.mapiconhover03 p{color:#000;}
.mapicongif03 a:hover .mapiconhover03{display:block;}
.mapiconhover03 h3{font-family: 'SF-Pro-Display-Bold', sans-serif; font-size:20px; color:#d7182a; margin-top:10px;}
.mapiconhover03 p{margin:0px;}




.mapicongif04{position:absolute; right:10%; bottom:39%;}
.mapicongif04 a{position:relative;}
.mapicongif04 a img{width:50px;}

.mapiconhover04{background:rgba(255, 255, 255, 0.7); padding:15px; border-radius:5px; text-align:center; display:none; position:absolute; width:200px; left:-120px; bottom:30px;}
.mapiconhover04 p{color:#000;}
.mapicongif04 a:hover .mapiconhover04{display:block;}
.mapiconhover04 h3{font-family: 'SF-Pro-Display-Bold', sans-serif; font-size:20px; color:#d7182a; margin-top:10px;}
.mapiconhover04 p{margin:0px;}

.mapicongif05{position:absolute; right:36%; bottom:0%;}
.mapicongif05 a{position:relative;}
.mapicongif05 a img{width:50px;}

.mapiconhover05{background:rgba(255, 255, 255, 0.7); padding:15px; border-radius:5px; text-align:center; display:none; position:absolute; width:200px; left:-120px; bottom:30px;}
.mapiconhover05 p{color:#000;}
.mapicongif05 a:hover .mapiconhover05{display:block;}
.mapiconhover05 h3{font-family: 'SF-Pro-Display-Bold', sans-serif; font-size:20px; color:#d7182a; margin-top:10px;}
.mapiconhover05 p{margin:0px;}


.padd120{padding-top:120px;}

.padd10-0{padding-top:10px; padding-bottom:10px;}


.padabouts{padding: 40px 0px 0px 0px!important; }

/*131020*/

.mapicongif06{position:absolute; right:35%; bottom:35%;}
.mapicongif06 a{position:relative;}
.mapicongif06 a img{width:50px;}

.mapiconhover06{background:rgba(255, 255, 255, 0.7); padding:15px; border-radius:5px; text-align:center; display:none; position:absolute; width:200px; left:-70px; bottom:30px;}
.mapiconhover06 p{color:#000;}
.mapicongif06 a:hover .mapiconhover06{display:block;}
.mapiconhover06 h3{font-family: 'SF-Pro-Display-Bold', sans-serif; font-size:20px; color:#d7182a; margin-top:10px;}
.mapiconhover06 p{margin:0px;}

.mapicongif07{position:absolute; right:48%; bottom:43%;}
.mapicongif07 a{position:relative;}
.mapicongif07 a img{width:50px;}

.mapiconhover07{background:rgba(255, 255, 255, 0.7); padding:15px; border-radius:5px; text-align:center; display:none; position:absolute; width:200px; left:-60px; bottom:30px;}
.mapiconhover07 p{color:#000;}
.mapicongif07 a:hover .mapiconhover07{display:block;}
.mapiconhover07 h3{font-family: 'SF-Pro-Display-Bold', sans-serif; font-size:20px; color:#d7182a; margin-top:10px;}
.mapiconhover07 p{margin:0px;}



.stepsnum{display:inline-block; width:60px; height:60px; line-height:60px; color:#fff; border-radius:50%; background:#11639e; font-family: 'SF-Pro-Display-Bold', sans-serif; font-size:20px; margin-bottom:20px;}


.corporate-bottombg-box-big03{min-height:270px;}
.corporate-bottombg-box-big03 span{padding-bottom:90px;}


.pressreleases{color:#d7182a; margin-top:10px; display:inline-block;}
.pressreleases:hover{color:#000;}


.numbox{float:left; width:4%;}
.conbox{float:left; width:96%;}


.termsbg{float:left; width:100%;}
.termsbg h3{font-family: 'SF-Pro-Display-Bold', sans-serif; font-size:20px; color:#d7182a; margin:20px 0px;}

.termsbg-padd{padding-left:30px;}
.termsbg-padd p{float:left; width:100%; padding-left:25px;}
.termsbg-padd ul{float:left; width:100%;}

.privacybg .termsbg-padd{padding-left:0px;}


@media only screen and (min-width: 1900px) and (max-width: 2560px) {
}

@media only screen and (min-width: 1600px) and (max-width: 1899px) {
}

@media only screen and (min-width: 1440px) and (max-width: 1599px) {
}

@media only screen and (min-width: 960px) and (max-width: 1140px) {
.easy-integration-toprow-logo a{margin:0px 5px;}
.userlogos a{margin:0px 1px;}
.easy-integration-bottomrow h2{text-align:center; display:block;}
.easy-integration-bottomrow p{width:100%; text-align:center;}
.oneplatformrow li span{font-size:11px;}
.security-compliance-content{padding-top:80px;}
.convenience-dashboard-content{padding:30px 0 0 0px;}
.go-digital-content{padding:15px;}
.apple-pay-content{padding:30px;height:auto;}
.app-card-tabs .nav-tabs li a{padding:10px; font-size:14px;}
.go-digital-content h1{font-size:33px;}
.apple-pay-content h1{font-size:33px;}
.trusted-brand{min-height:430px;}
.usefullinksbg{margin-left:25px;}
.footerabout{width:250px;}
.footerabout img{max-width:100%;}
.rellax{transform: translate3d(0px, 0px, 0px)!important;}
.content-part{margin-top:0px!important;}
.casesimgbox a{font-size:14px;}
.corporate-key-benefits-box{min-height:410px;}

.apple-pay-landing-banner{height:300px;}
.apple-pay h1{width:100%;}
.apple-pay h5{padding:0;}
.apple-pay-landing li:nth-child(2){margin:5px 50px;}
.apple-pay-steps img{width:150px;}
.apple-pay-steps li p{height:180px;}
.apple-pay-footer h5 {padding: 0;}
.apple-pay-footer img {width:150px}
.apple-pay-footer{background-size: auto;height: auto;}

/*270820*/
.media-banner-box p {color: #1f1f1f; padding: 0% 5%;}
.media-banner-box h3 {color: #1f1f1f; padding: 0% 5%;}
.medialogoinfo ul li {padding: 10px 15px;}
.medialogoinfo ul li img{width: 110px;}

.aboutbannertxt {padding-top: 20px; padding: 40px 10px 0px 0px;}
.awardbox{min-height: auto;}
.awardbox img{width: 100%;}

.aboutinfomatter h1 {font-size: 35px;}
.aboutinfomatter h5 {font-size: 20px; margin: 0px 0px 20px;}
.yearlydatetxt {margin-left: 2px;}

.aboutbannertxt h1 {font-size: 30px; line-height: 35px;}

/*****02-09*****/

.lightspeed-stepsimg img{max-width:500px;}

/*131020*/

.bannershowbox h3 {font-size: 30px;}
.bannershowbox span {font-size: 13px;}

.subtrabannerimg{width:650px;}
subtrabannerimg img{max-width:100%;}
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
.navbar-brand img{width:150px;}
.bannertext{padding:50px 0px; text-align:center;}
.easy-integration-bottomrow{text-align:center;}
.easy-integration-bottomrow p{width:100%;}
.oneplatformrow li{width:33.33%; min-height:100px;}
.security-compliance-content{padding-top:0px;}
.convenience-dashboard-content{padding:0px;}
.go-digital-content{padding:15px;}
.go-digital-content h1{font-size:20px; margin-bottom:10px;}
.go-digital-content p{font-size:18px; line-height:25px; margin-bottom:15px;}
.apple-pay-content{padding:30px;height:auto;}
.apple-pay-content h1{font-size:30px; margin-bottom:10px;}
.app-card-tabs .nav-tabs li a{font-size:11px; padding:10px 5px;}
.trusted-brand{min-height:inherit;}
.usefullinksbg{margin:0px; width:100%;}
.footerabout{width:100%; padding-bottom:30px;}
.navbar-nav li{margin-left:18px;}
.headertop-right li{padding-left:12px;}
.rellax{transform: translate3d(0px, 0px, 0px)!important;}
.howitwork-bg li{width:49%;}
.content-part{margin-top:0px!important;}
.va-banner-content h1{font-size:38px;}
.howitbox{width:48%; margin:1%; float:left; padding:0px;}
.howitbox span::after{display:none;}

.howitworkbg li{width:48%; margin:1%; border:none;}
.key-benefits-bg li{width:48%; margin:1%; border:none; text-align:center;}
.casesimgbox{margin:15px 0px;}
.casesimgbox a{font-size:9px;}

.apple-pay-landing-banner{height:200px;}
.apple-pay h1{width:100%;}
.apple-pay h5{padding:0;}
.apple-pay-landing li:nth-child(2){margin:5px 50px;}
.apple-pay-steps img{width:150px;}
.apple-pay-steps li p{height:auto;}
.apple-pay-footer h5 {padding: 0;}
.apple-pay-footer img {width:150px}
.apple-pay-footer{background-size: cover;height: auto;}

/*270820*/
.media-banner-box p {color: #1f1f1f; padding: 0% 5%;}
.media-banner-box h3 {color: #1f1f1f; padding: 0% 5%;}
.medialogoinfo ul li {padding: 10px 15px;}
.medialogoinfo ul li img{width: 95px;}

.aboutbannertxt {padding-top: 20px; padding: 10px 0px 0px 0px;}
.aboutbannertxt h1{text-align:center;}
.aboutbannertxt p{width: 100%; text-align: center;}
.padaward{padding: 10px 0px 50px;}
.media-relasttop {width: 100%; padding: 0px 0px 20px;}
.bannershow{margin-bottom: 40px;}
.aboutshwinfoimg{margin: 10px 0px;}
.aboutinfomatter{padding-top: 25px;}

.padimgab01 {margin: 121px 0px 25px;}
.yearlydatetxt {width: 100%; margin-left: -8px;}
.padlefcont{padding-left: 0px;}

.angentlogos ul li {width: 30%;}
.awardbox img{width: 100%; float: left;}
.busneisaboutext {width: 100%; padding: 0% 0%; margin-bottom: 20px;}
.aboutoverimg01 {margin: 15px 0px; width: 100%;}   
.viewpotionbtn{float: left;}


/*****02-09*****/

.lightspeedbanner-text{text-align:center; padding-bottom:30px; padding-top:0px;}
.lightspeedbanner-text h1{font-size:28px;}
.lightspeedbanner-text p{font-size:22px; line-height:32px;}
.growingrow{padding:100px 0px;}
.digitalcashbg{padding:100px 0px; text-align:center;}
.digitalcashbg ul{width:100%;}
.digitalcashbg ul li span{float:none; display:block; width:100%; margin:0 0 15px 0px;}
.caseandclienttabs .tab-pane{padding:0px;}
.caseandclienttabs .tab-pane img{max-width:100%;}
.opportunitybg-bottomtext{padding:0px;}
.integrationimgbox{padding:30px 0px;}
.lightspeed-stepsimg{position:inherit;}
.lightspeed-stepsimg img{max-width:100%; padding-top:30px;}
.lightspeed-stepscontent{width:100%;}
.lightspeed-stepscontent .stepno{top:-120px;}
.lightspeed-stepscontent p span{margin-bottom:65px;}
.lightspeed-reviews-content h4{font-size:25px; line-height:35px; margin-bottom:30px;}
.lightspeed-stepsimg{text-align:right;}
.lightspeed-stepsrow-pink .lightspeed-stepsimg{text-align:left;}
.mapiconhover{width:260px;}

/*051020*/
.mapiconhover{z-index: 999;}
.mapiconhover01 {padding: 10px; width: 280px; left: 5px; z-index: 99;}
.mapiconhover02 {padding: 10px; width: 280px; left: 5px;}
.mapiconhover03 {padding: 10px; width: 280px; left: 5px;}
.mapiconhover04 {padding: 10px; width: 280px; left: -210px;}
.mapiconhover05 {padding: 10px; width: 280px; left: -240px;}

/*131020*/

.padabouts {padding: 10px 0px 0px 0px!important;}

.subtrabannerimg{position:inherit; width:100%;}
.subtrabannerimg img{width:100%;}
}
@media only screen and (min-width: 600px) and (max-width: 767px) {
.bannertext{padding:35px 0px;}
.headertop-left{padding-bottom:15px;}
.headertop-left, .headertop-right{width:100%; text-align:center;}
.headertop-right li{float:none; display:inline-block; padding:0px 10px;}
.navbar-nav li{padding:10px 0px; border-bottom:solid 1px #ccc; margin:0px;}
.bannertext{padding:25px 0px; text-align:center;}
.bannertext h1{font-size:25px; line-height:30px;}
.bannertext h1 sup{font-size:18px;}
.easy-integration-toprow h1{padding:0px; font-size:24px; text-align:center;}
.easy-integration-toprow-logo{padding-left:0px; border:none;}
.easy-integration-midrowtext{text-align:center;}
.easy-integration-bottomrow{text-align:center;}
.easy-integration-bottomrow p{float:none; width:100%;}
.oneplatformrow li{width:31%; padding:10px; float:none; display:inline-block;}
.security-compliance-content{padding:25px 0px; text-align:center;}
.convenience-dashboard-content{padding:25px 0 0 0px; text-align:center;}
.go-digital-bg-inner .col-md-6{padding:0px 15px!important;}
.go-digital-content{padding:20px;}
.apple-pay-content{padding:50px 100px;margin-left:20px;width:99%;}
.app-card-tabs .nav-tabs li{padding:3px; width:50%;}
.app-card-tabs .nav-tabs li a{border-radius:5px;}
.app-card-content img{max-width:100%; margin:5px 0px;}
.footerabout{width:100%;}
.footerabout img{max-width:100%;}
.usefullinksbg{width:100%; margin:25px 0px;}
.footertop-right{width:100%;}
.footerbottom{text-align:center;}
.trusted-brand{min-height:inherit;}
.navbar-nav > li .subnav{left:0px;}
.go-digital-bg-inner .play-area{top:56%;}
.easy-integration-midrowtext{margin-bottom:20px;}
.rellax{transform: translate3d(0px, 0px, 0px)!important;}
.featured-in li{width:32%; margin:3px 0px;}
.howitwork-bg li{width:100%;}
.benefits-left-row li{width:100%;}
.poweryowrwork-bg{text-align:center;}
.poweryowrwork-bg h2{display:block;}
.poweryowrwork-bg a{float:none; margin:20px 0px;}

.apple-pay-landing-banner{height:200px;}
.apple-pay h1{width:100%;padding:20px;text-align:left;}
.apple-pay .container{padding-bottom:20px;}
.apple-pay h5{padding:0;}
.apple-pay-landing li{width:100%;padding:10px;min-height:auto;}
.apple-pay-landing li:nth-child(2){margin:5px 0;}
.apple-pay-steps img{width:150px;}
.apple-pay-steps li p{height:auto;}
.apple-pay-footer h5 {padding: 0;}
.apple-pay-footer img {width:150px}
.apple-pay-footer{background-size: cover;height: auto;text-align:left;}
.apple-pay-info li{text-align:left;width:100%;margin:5px 0 !important;}
.apple-pay-text{text-align:left;}
.apple-pay-faq li{width:100%;padding:10px;min-height:auto;margin: 20px 0px}
.apple-pay-faq h2 {padding-left:10px}

/*110720*/

.toppadsss {margin-top: 0px; float: left; width: 100%;}
.SimpleSteps{width: 100%; float: left; display: inherit!important;}
.section.section-steps .SimpleSteps__step{width: 100%; float: left; margin-bottom: 45px;}
.section.section-steps .SimpleSteps::before{display: none;}
.padmob{padding: 0px 0px!important;}
.content-part{margin-top:0px!important;}
.va-banner-content h1{font-size:17px; line-height:28px;}
.btnstyl102, .btnstyl103{padding:10px 20px; font-size:14px;}
.part-tese .gallery_container .gallery_wrap img{max-width:250px!important;}
.part-tese .gallery_container .gallery_wrap{width:250px!important;}
.va-banner-img, .va-banner-img img{height:380px;}
.howitbox{width:100%;}
.howitbox span::after{display:none;}

.howitworkbg li{width:100%; border:none;}
.section .section-title{font-size:35px!important;}
.key-benefits-bg li{width:100%; border:none; text-align:center;}
.casesimgbox{margin:15px 0px;}
.corporate-bottombg-box{text-align:center;}
.corporate-bottombg-box span{float:none; padding:0 0 15px 0px; display:inline-block;}
.usecase-bg-inner-content{padding:20px;}
.corporate-key-benefits-box{text-align:center; min-height:inherit;}

/*270820*/
.media-banner-box p {color: #1f1f1f; padding: 0% 5%;}
.media-banner-box h3 {color: #1f1f1f; padding: 0% 5%;}
.medialogoinfo ul li {padding: 10px 15px;}
.medialogoinfo ul li img{width: 120px;}
.mediaboxshow{min-height: auto;}
.our-janouryinfomain{display: none;}


.aboutbannertxt {padding-top: 20px; padding: 10px 0px 0px 0px;}
.aboutbannertxt h1{text-align:center; font-size: 30px; line-height: 40px;}
.aboutbannertxt p{width: 100%; text-align: center;}
.padaward{padding: 10px 0px 50px;}
.media-relasttop {width: 100%; padding: 0px 0px 20px;}
.aboutshwinfoimg{margin: 10px 0px;}
.aboutinfomatter{padding-top: 15px;}

.aboutshwinfoimg{padding:0px;}
.padaoboutss {padding-left: 15px;}
.padimgab01 {margin: 15px 0px 0px;}
.aboutoverimg01 { position: relative; width: 100%; margin: 5px 0px;}

.bannershow{margin-bottom: 50px;}
.angentlogos ul li {width: 40%; margin: 10px 15px;}
.awardbox img{width: 100%; float: left;}
.busneisaboutext {width: 100%; padding: 0% 0%; margin-bottom: 20px;}
.aboutoverimg01 {margin: 15px 0px; width: 100%;}   
.viewpotionbtn{float: left;}
.bannershow ul {margin: 0px 0px 0px 0px;}
.bannershowbox h3 {font-size: 22px; margin-bottom: 5px;}
.middle{width: 100%;}


/*****02-09*****/

.lightspeedbanner-text{text-align:center; padding-bottom:30px; padding-top:0px;}
.lightspeedbanner-text h1{font-size:28px;}
.lightspeedbanner-text p{font-size:22px; line-height:32px;}
.growingrow{padding:100px 0px;}
.digitalcashbg{padding:100px 0px; text-align:center;}
.digitalcashbg ul{width:100%;}
.digitalcashbg ul li span{float:none; display:block; width:100%; margin:0 0 15px 0px;}
.caseandclienttabs .tab-pane{padding:0px;}
.caseandclienttabs .tab-pane img{max-width:100%;}
.opportunitybg-bottomtext{padding:0px;}
.integrationimgbox{padding:30px 0px;}
.lightspeed-stepsimg{position:inherit;}
.lightspeed-stepsimg img{max-width:100%; padding-top:30px;}
.lightspeed-stepscontent{width:100%;}
.lightspeed-stepscontent .stepno{top:-120px;}
.lightspeed-stepscontent p span{margin-bottom:65px;}
.lightspeed-reviews-content h4{font-size:25px; line-height:35px; margin-bottom:30px;}

/*051020*/
.mapiconhover{z-index: 999;}
.mapiconhover01 {padding: 10px; width: 280px; left: 5px; z-index: 99;}
.mapiconhover02 {padding: 10px; width: 280px; left: 5px;}
.mapiconhover03 {padding: 10px; width: 280px; left: 5px;}
.mapiconhover04 {padding: 10px; width: 280px; left: -210px;}
.mapiconhover05 {padding: 10px; width: 280px; left: -240px;}

/*131020*/
.aboutbannre {margin: 50px 0px;}
.padabouts {padding: 0px 0px 0px 0px!important;}

.subtrabannerimg{position:inherit; width:100%;}
.subtrabannerimg img{width:100%;}
.navbar-nav > li .subnav ul{margin-top:10px;}
.numbox{width:10%;}
.conbox{width:90%;}
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
.navbar-brand img{width:210px;}
.headertop-left{padding-bottom:15px;}
.headertop-left, .headertop-right{width:100%; text-align:center;}
.headertop-right li{float:none; display:inline-block; padding:0px 10px;}
.navbar-nav li{padding:10px 0px; border-bottom:solid 1px #ccc; margin:0px;}
.bannertext{padding:25px 0px; text-align:center;}
.bannertext h1{font-size:25px; line-height:30px;}
.bannertext h1 sup{font-size:18px;}
.easy-integration-toprow h1{padding:0px; font-size:24px; text-align:center;}
.easy-integration-toprow-logo{padding-left:0px; border:none;}
.easy-integration-midrowtext{text-align:center;}
.easy-integration-bottomrow{text-align:center;}
.easy-integration-bottomrow p{float:none;}
.oneplatformrow li{width:100%; padding:10px;}
.security-compliance-content{padding:25px 0px; text-align:center;}
.convenience-dashboard-content{padding:25px 0 0 0px; text-align:center;}
.go-digital-bg-inner .col-md-6{padding:0px 15px!important;}
.go-digital-content{padding:20px;}
.apple-pay-content{padding:50px 78px;margin-left:20px;width:99%}
.app-card-tabs .nav-tabs li{padding:3px; width:100%;}
.app-card-tabs .nav-tabs li a{border-radius:5px;}
.app-card-content img{max-width:100%; margin:5px 0px;}
.footerabout{width:100%;}
.footerabout img{max-width:100%;}
.usefullinksbg{width:100%; margin:25px 0px;}
.footertop-right{width:100%;}
.footerbottom{text-align:center;}
.navbar-nav > li .subnav{width:300px; left:0px;}
.navbar-nav > li .subnav ul{width:98%;}
.navbar-nav > li ul li{border:none;}
.go-digital-bg-inner .play-area{top:49%;}
.easy-integration-midrowtext{margin-bottom:20px;}
.rellax{transform: translate3d(0px, 0px, 0px)!important;}
.featured-in li{width:48%; margin:3px 0px;}
.howitwork-bg li{width:100%;}
.benefits-left-row li{width:100%;}
.poweryowrwork-bg{text-align:center;}
.poweryowrwork-bg h2{display:block;}
.poweryowrwork-bg a{float:none; margin:20px 0px;}

.apple-pay-landing-banner{height:350px;background:url(../images/apple-pay-banner-mobile.jpg) no-repeat;background-size:cover;}
.apple-pay h1{width:100%;padding:20px;text-align:left;}
.apple-pay .container{padding-bottom:20px;}
.apple-pay h5{padding:0;text-align:left;}
.apple-pay-landing li{width:100%;padding:10px;min-height:auto;}
.apple-pay-landing li:nth-child(2){margin:5px 0;}
.apple-pay-steps img{width:150px;}
.apple-pay-steps li p{height:auto;}
.apple-pay-footer h5 {padding: 0;}
.apple-pay-footer img {width:150px}
.apple-pay-footer{background-size: cover;height: auto;text-align:left;}
.apple-pay-info li{text-align:left;width:100%;margin:5px 0 !important;}
.apple-pay-text{text-align:left;padding:20px;}
.apple-pay-faq {margin-top:20px;padding:0 20px;}
.apple-pay-faq .container{padding:40px 0 0;}
.apple-pay-faq li{width:100%;padding:10px;min-height:auto;margin: 20px 0px}
.apple-pay-faq h2 {padding-left:10px}

/*110720*/

.toppadsss {margin-top: 0px; float: left; width: 100%;}
.SimpleSteps{width: 100%; float: left; display: inherit!important;}
.section.section-steps .SimpleSteps__step{width: 100%; float: left; margin-bottom: 45px;}
.section.section-steps .SimpleSteps::before{display: none;}
.padmob{padding: 0px 0px!important;}
.content-part{margin-top:0px!important;}
.va-banner-content h1{font-size:17px; line-height:28px;}
.btnstyl102, .btnstyl103{padding:10px 20px; font-size:14px;}
.part-tese .gallery_container .gallery_wrap img{max-width:250px!important;}
.part-tese .gallery_container .gallery_wrap{width:250px!important;}
.va-banner-img, .va-banner-img img{height:380px;}
.howitbox{width:100%;}
.howitbox span::after{display:none;}

.howitworkbg li{width:100%; border:none;}
.section .section-title{font-size:35px!important;}
.key-benefits-bg li{width:100%; border:none; text-align:center;}
.casesimgbox{margin:15px 0px;}
.corporate-bottombg-box{text-align:center;}
.corporate-bottombg-box span{float:none; padding:0 0 15px 0px; display:inline-block;}
.usecase-bg-inner-content{padding:20px;}
.corporate-key-benefits-box{text-align:center; min-height:inherit;}


/*270820*/
.media-banner-box p {color: #1f1f1f; padding: 0% 5%;}
.media-banner-box h3 {color: #1f1f1f; padding: 0% 5%;}
.medialogoinfo ul li {padding: 10px 15px;}
.medialogoinfo ul li img{width: 120px;}
.mediaboxshow{min-height: auto;}
.our-janouryinfomain{display: none;}

.aboutbannertxt {padding-top: 20px; padding: 10px 0px 0px 0px;}
.aboutbannertxt h1{text-align:center; font-size: 36px;}
.aboutbannertxt p{width: 100%; text-align: center;}
.padaward{padding: 10px 15px 50px;}
.media-relasttop {width: 100%; padding: 0px 0px 20px;}
.aboutshwinfoimg{margin: 10px 0px;}
.aboutinfomatter{padding-top: 15px;}
.aboutinfomatter h1 {font-size: 35px;}


.aboutinfomatter h4{text-align: center;}
.aboutshwinfoimg{padding:0px;}
.padaoboutss {padding-left: 15px;}
.padimgab01 {margin: 15px 0px 0px;}
.aboutoverimg01 { position: relative; width: 100%; margin: 5px 0px;}
.aboutinfomatter h5{text-align: center; font-size: 18px; margin: 5px 0px;}
.mediatopbannerbg {padding-bottom: 9px;}
.media-banner-box {margin: 50px 0px 0px;}

.bannershow{margin-bottom: 50px;}
.bannershow ul li {float: left; list-style-type: none; width: 50%; margin-bottom: 10px;}
.awardbox{width: 100%; float: left;}
.awardbox01 img{width: 100%; float: left;}
.busneisaboutext {width: 100%; padding: 0% 0%; margin-bottom: 20px;}
.aboutoverimg01 {margin: 15px 0px; width: 100%;}   
.viewpotionbtn {float: none; display: table; margin: 0px auto;}
.bannershow ul {margin: 0px 0px 0px 0px;}
.middle{width: 100%;}
.angentlogos ul li {width: 100%; display: inline-block; margin: 10px 0px;}
.aboutinfomatter p{text-align: center;}
.matchcluarmatter p{text-align: center; width: 100%; float: left;}


/*****02-09*****/

.lightspeedbanner-text{text-align:center; padding-bottom:30px; padding-top:0px;}
.lightspeedbanner-text h1{font-size:28px;}
.lightspeedbanner-text p{font-size:22px; line-height:32px;}
.growingrow{padding:100px 0px;}
.digitalcashbg{padding:100px 0px; text-align:center;}
.digitalcashbg ul{width:100%;}
.digitalcashbg ul li span{float:none; display:block; width:100%; margin:0 0 15px 0px;}
.caseandclienttabs .tab-pane{padding:0px;}
.caseandclienttabs .tab-pane img{max-width:100%;}
.opportunitybg-bottomtext{padding:0px;}
.integrationimgbox{padding:30px 0px;}
.lightspeed-stepsimg{position:inherit;}
.lightspeed-stepsimg img{max-width:100%; padding-top:30px;}
.lightspeed-stepscontent{width:100%;}
.lightspeed-stepscontent .stepno{top:-120px;}
.lightspeed-stepscontent p span{margin-bottom:65px;}
.lightspeed-reviews-content h4{font-size:25px; line-height:35px; margin-bottom:30px;}

/*051020*/
.mapiconhover{z-index: 999;}
.mapiconhover01 {padding: 10px; width: 280px; left: 5px; z-index: 99;}
.mapiconhover02 {padding: 10px; width: 280px; left: 5px;}
.mapiconhover03 {padding: 10px; width: 280px; left: 5px;}
.mapiconhover04 {padding: 10px; width: 280px; left: -210px;}
.mapiconhover05 {padding: 10px; width: 280px; left: -240px;}


/*131020*/
.aboutbannre {margin: 50px 0px 0px;}
.padabouts {padding: 0px 0px 0px 0px!important;}

.subtrabannerimg{position:inherit; width:100%;}
.subtrabannerimg img{width:100%;}
.navbar-nav > li .subnav ul{margin-top:10px;}
.numbox{width:10%;}
.conbox{width:90%;}
}
@media only screen and (max-width: 479px) {
.headertop{padding:5px 0px;}
.navbar{padding:12px 15px;}
.navbar-brand img{width:210px;}
.headertop-left, .headertop-right{width:100%; text-align:center; }
.headertop-right li{float:none; display:inline-block; padding:0px 10px;}
.navbar-nav li{padding:10px 0px; border-bottom:solid 1px #ccc; margin:0px;}
.bannertext{padding:25px 0px; text-align:center;}
.bannertext h1{font-size:25px; line-height:30px;}
.bannertext h1 sup{font-size:18px;}
.easy-integration-toprow h1{padding:0px; font-size:24px; text-align:center;}
.easy-integration-toprow-logo{padding-left:0px; border:none;}
.easy-integration-midrowtext{text-align:center;}
.easy-integration-bottomrow{text-align:center;}
.easy-integration-bottomrow p{float:none;}
.oneplatformrow li{width:100%; padding:10px;}
.security-compliance-content{padding:25px 0px; text-align:center;}
.convenience-dashboard-content{padding:25px 0 0 0px; text-align:center;}
.go-digital-bg-inner .col-md-6{padding:0px 15px!important;}
.go-digital-content{padding:20px;}
.apple-pay-content{padding:50px 30px;margin-left:17px;width:99%;}
.apple-pay-content h1{font-size:36px;}
.apple-pay-content img{margin:0 auto 40px;display:block;width:100%;}
.apple-img img{margin-left: -15px;}
.app-card-tabs .nav-tabs li{padding:3px; width:100%;}
.app-card-tabs .nav-tabs li a{border-radius:5px;}
.app-card-content img{max-width:100%; margin:5px 0px;}
.footerabout{width:100%;}
.footerabout img{max-width:100%;}
.usefullinksbg{width:100%; margin:25px 0px;}
.footertop-right{width:100%;}
.footerbottom{text-align:center;}
.navbar-nav > li .subnav{width:300px; left:0px;}
.navbar-nav > li .subnav ul{width:98%;}
.navbar-nav > li ul li{border:none;}
.go-digital-bg-inner .play-area{top:33%; left:52%;}
.easy-integration-midrowtext{margin-bottom:20px;}
.rellax{transform: translate3d(0px, 0px, 0px)!important;}
.featured-in li{width:48%; margin:3px 0px;}
.howitwork-bg li{width:100%;}
.benefits-left-row li{width:100%;}
.poweryowrwork-bg{text-align:center;}
.poweryowrwork-bg h2{display:block;}
.poweryowrwork-bg a{float:none; margin:20px 0px;}

.apple-pay-landing-banner{height:270px;background:url(../images/apple-pay-banner-mobile.jpg) no-repeat;background-size:cover;}
.apple-pay h1{width:100%;padding:0;text-align:left;}
.apple-pay{padding:20px;}
.apple-pay .container{padding-bottom:20px;}
.apple-pay h5{padding:0;text-align:left;}
.apple-pay-landing li{width:100%;padding:0;min-height:auto;margin:5px 0 30px;}
.apple-pay-landing li:nth-child(2){margin:5px 0 30px;}
.apple-pay-steps img{width:150px;}
.apple-pay-steps li p{height:auto;}
.apple-pay-footer h5 {padding: 0;}
.apple-pay-footer img {width:150px}
.apple-pay-footer{background-size: cover;height: auto;text-align:left;padding:20px;}
.apple-pay-info li{text-align:left;width:100%;margin:5px 0 30px !important;}
.apple-pay-text{text-align:left;padding:20px;}
.apple-pay-bg-inner{padding:0 15px;}
.apple-pay-faq {margin-top:20px;padding:0 20px;}
.apple-pay-faq .container{padding:40px 0 0;}
.apple-pay-faq li{width:100%;padding:10px;min-height:auto;margin: 20px 0px}
.apple-pay-faq h2 {padding-left:10px}

/*110720*/

.toppadsss {margin-top: 0px; float: left; width: 100%;}
.SimpleSteps{width: 100%; float: left; display: inherit!important;}
.section.section-steps .SimpleSteps__step{width: 100%; float: left; margin-bottom: 45px;}
.section.section-steps .SimpleSteps::before{display: none;}
.padmob{padding: 0px 0px!important;}
.content-part{margin-top:0px!important;}
.va-banner-content h1{font-size:17px; line-height:28px;}
.btnstyl102, .btnstyl103{padding:10px 20px; font-size:14px;}
.part-tese .gallery_container .gallery_wrap img{max-width:250px!important;}
.part-tese .gallery_container .gallery_wrap{width:250px!important;}
.va-banner-img, .va-banner-img img{height:380px;}
.howitbox{width:100%;}
.howitbox span::after{display:none;}

.howitworkbg li{width:100%; border:none;}
.section .section-title{font-size:35px!important;}
.key-benefits-bg li{width:100%; border:none; text-align:center;}
.casesimgbox{margin:15px 0px;}
.corporate-bottombg-box{text-align:center;}
.corporate-bottombg-box span{float:none; padding:0 0 15px 0px; display:inline-block;}
.usecase-bg-inner-content{padding:20px;}
.corporate-key-benefits-box{text-align:center; min-height:inherit;}

/*270820*/
.media-banner-box p {color: #1f1f1f; padding: 0% 5%;}
.media-banner-box h3 {color: #1f1f1f; padding: 0% 5%;}
.medialogoinfo ul li {padding: 10px 15px;}
.medialogoinfo ul li img{width: 120px;}
.mediaboxshow{min-height: auto;}
.our-janouryinfomain{display: none;}

.aboutbannertxt {padding-top: 20px; padding: 10px 0px 0px 0px;}
.aboutbannertxt h1{text-align:center; font-size: 30px; line-height: 35px;}
.aboutbannertxt p{width: 100%; text-align: center;}
.padaward{padding: 10px 15px 50px;}
.media-relasttop {width: 100%; padding: 0px 0px 20px;}
.aboutshwinfoimg{margin: 10px 0px;}
.aboutinfomatter{padding-top: 15px;}
.aboutinfomatter h1 {font-size: 30px; line-height: 40px; text-align: center;}
.aboutinfomatter p{width: 100%; text-align: center;}

.aboutinfomatter h4{text-align: center;}
.aboutshwinfoimg{padding:0px;}
.padaoboutss {padding-left: 15px;}
.padimgab01 {margin: 15px 0px 0px;}
.aboutoverimg01 { position: relative; width: 100%; margin: 5px 0px;}
.aboutinfomatter h5{text-align: center; font-size: 18px; margin: 5px 0px;}
.mediatopbannerbg {padding-bottom: 100px;}
.media-banner-box {margin: 50px 0px 0px;}

.padinginfo ul li a {padding: 10px 14px; font-size: 15px;}

.bannershowbox h3{font-size: 30px;}
.bannershow{margin-bottom: 50px;}
.bannershow ul li {float: left; list-style-type: none; width: 50%; margin-bottom: 10px;}
.awardbox{width: 100%; float: left;}
.awardbox img{width: 100%; float: left;}
.busneisaboutext {width: 100%; padding: 0% 0%; margin-bottom: 20px;}
.aboutoverimg01 {margin: 15px 0px; width: 100%;}   
.viewpotionbtn {float: none; display: table; margin: 0px auto;}
.bannershow ul {margin: 0px 0px 0px 0px;}
.middle{width: 100%;}
.angentlogos ul li {width: 100%; display: inline-block; margin: 10px 0px;}
.aboutinfomatter p{text-align: center;}
.matchcluarmatter p{text-align: center; width: 100%; float: left;}


/*****02-09*****/

.lightspeedbanner-text{text-align:center; padding-bottom:30px; padding-top:0px;}
.lightspeedbanner-text h1{font-size:28px;}
.lightspeedbanner-text p{font-size:22px; line-height:32px;}
.growingrow{padding:100px 0px;}
.digitalcashbg{padding:100px 0px; text-align:center;}
.digitalcashbg ul{width:100%;}
.digitalcashbg ul li span{float:none; display:block; width:100%; margin:0 0 15px 0px;}
.caseandclienttabs .tab-pane{padding:0px;}
.caseandclienttabs .tab-pane img{max-width:100%;}
.opportunitybg-bottomtext{padding:0px;}
.integrationimgbox{padding:30px 0px;}
.lightspeed-stepsimg{position:inherit;}
.lightspeed-stepsimg img{max-width:100%; padding-top:30px;}
.lightspeed-stepscontent{width:100%;}
.lightspeed-stepscontent .stepno{top:-120px;}
.lightspeed-stepscontent p span{margin-bottom:65px;}
.lightspeed-reviews-content h4{font-size:25px; line-height:35px; margin-bottom:30px;}
.mapiconhover{width:260px;}

/*051020*/
.mapiconhover{z-index: 999;}
.mapiconhover01 {padding: 10px; width: 280px; left: 5px; z-index: 99;}
.mapiconhover02 {padding: 10px; width: 280px; left: 5px;}
.mapiconhover03 {padding: 10px; width: 280px; left: 5px;}
.mapiconhover04 {padding: 10px; width: 280px; left: -210px;}
.mapiconhover05 {padding: 10px; width: 280px; left: -240px;}
.mapicongif02 {position: absolute; left: 4%; bottom: 38%;}
.mapicongif03 {left: 8%; bottom: 33%;}
.mapicongif {bottom: 13%;}
.mapicongif01 {left: -3%; bottom: 54%;}


/*131020*/
.aboutbannre {margin: 50px 0px 0px;}
.padabouts {padding: 0px 0px 0px 0px!important;}

.subtrabannerimg{position:inherit; width:100%;}
.subtrabannerimg img{width:100%;}
.navbar-nav > li .subnav ul{margin-top:10px;}
.numbox{width:10%;}
.conbox{width:90%;}
}
@media only screen and (max-width: 430px) {
  .apple-pay-landing-banner{height:270px;background:url(../images/apple-pay-banner-mobile.jpg) no-repeat;background-size:contain;}
}

@media only screen and (max-width: 320px) {

/*051020*/
.mapiconhover{z-index: 999;}
.mapiconhover03 {padding: 10px; width: 280px; left: -26px;}
.mapiconhover04 {padding: 10px; width: 280px; left: -205px;}
.mapiconhover05 {padding: 10px; width: 280px; left: -203px;}
.mapicongif02 {left: 1%; bottom: 38%;}
.mapicongif03 {left: 7%; bottom: 33%;}

}


.btomscroll {
    width: 100%;
    float: left;
    text-align: center;
    position: relative;
    z-index: 9;
    top: 22px;
}

.btomscroll a {
    background: #d7182a;
    border-radius: 100%;
    height: 70px;
    width: 70px;
    border: 5px solid #fff;
    display: inline-block;
    padding: 15px 21px 20px;
}

.btomscroll .fa {color: #fff; font-size: 25px;}

.virtulbanner{text-align:center; padding:30px 0px; position:relative; z-index:99;}
.virtulbanner img{width:auto; max-width:100%;}

/* colours */

.color-white {
  background-color: #e5eeff;
}
.color-violet {
  background-color: #c3dcff;
}
.color-indigo {
  background-color: #feede6;
}
